import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";
import Spacer from "./Spacer";
import { useMediaQuery } from "react-responsive";

const NewsFeed = ({
  isNews = false,
  isLeft = false,
  title = "",
  summary = "",
  summary2="",
  img = "",
  informerLink = "",
  date = "",
  link = "",
}) => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  console.log(img);
  return (
    <div
      onClick={() => window.open(link, "_blank")}
      className={`${styles?.["m-auto"]} ${styles?.["flexMobileWrap"]} ${styles?.["w-80"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
      style={{ gap: isMobile ? "0rem" : "2rem", cursor: "pointer" }}
    >
      {isLeft ? (
        <>
          <div
            style={{ lineHeight: isMobile?"22px":"33px", textAlign: "justify" }}
            className={` ${isMobile ? styles["w-100"] : styles?.["w-50"]}  ${
              styles?.["value-cover"]
            } `}
          >
            {isNews ? (
              <>
                <div>{date}</div>
                {/* <Spacer height={"1rem"} /> */}
                <div style={{ marginBottom: "1rem" }}>
                  <b>{informerLink}</b>
                </div>
              </>
            ) : (
              <></>
            )}
            {isNews ? (
              <h2>{summary}</h2>
            ) : (
              <>
                {" "}
                <h1 className={styles['head-lg']}>{title}</h1>
                <div className={styles["fade-content"]}>{summary}</div>
                <Spacer height={"2rem"} />
                <div className={styles["fade-content"]}>{summary2}</div>
              </>
            )}

            {isNews ? (
              <div
                onClick={() => window.open(link, "_blank")}
                style={{ color: "#FF6B6B", fontWeight: "500" }}
              >
                Read More
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className={`${isMobile ? styles["w-100"] : styles?.["w-50"]} `}>
            <img className={`${styles?.["news-img"]}`} src={img} alt="" />
          </div>
        </>
      ) : (
        <>
          <div className={`${isMobile ? styles["w-100"] : styles?.["w-50"]} `}>
            <img className={`${styles?.["news-img"]}`} src={img} alt="" />
          </div>
          <div
            style={{ lineHeight: isMobile?"22px":"33px" }}
            className={` ${isMobile ? styles["w-100"] : styles?.["w-50"]}  ${
              styles?.["value-cover"]
            } `}
          >
            {isNews ? (
              <>
                <div>{date}</div>
                {/* <Spacer height={"1rem"} /> */}
                <div style={{  marginBottom: "1rem" }}>
                  <b>{title}</b>
                </div>
              </>
            ) : (
              <></>
            )}
            {isNews ? (
              <h2>{summary}</h2>
            ) : (
              <>
                {" "}
                <h1 className={styles['head-lg']}>{title}</h1>
                <div className={styles["fade-content"]}>{summary}</div>
                <Spacer height={"2rem"} />
                <div className={styles["fade-content"]}>{summary2}</div>
              </>
            )}

            {isNews ? (
              <div
                onClick={() => window.open(link, "_blank")}
                style={{ color: "#FF6B6B", fontWeight: "500" }}
              >
                Read More
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default NewsFeed;
