import React, { useState } from "react";
import styles from "./Founders.module.css";
import FlexContainer from "../home/FlexContainer";
import Header from "./Header";
import rahul from "../../assets/Dev2/rahul.png";
import shitiz from "../../assets/Dev2/shitz.png";


const Founder = ({ name, title, description, experience, image ,flag=false}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.founderContainer} data-aos={flag?"fade-right":"fade-left"}>
     {flag? <img src={image} alt={name} className={styles.founderImage} />:<></>}
      <div className={styles.founderDetails}>
      <h3 >{title}</h3>
        <h2>{name}</h2>
        <p className={styles.expand}>{expanded ? description : `${description.substring(0, 200)}...`}</p>
        <button onClick={() => setExpanded(!expanded)} className={styles.readMore}>
          {expanded ? "Read Less" : "Read More"}
        </button>
        <div className={styles.experienceBox}>
          <h4>Experience</h4>
          <ol>
            {experience.map((exp, index) => (
              <li key={index}>{exp}</li>
            ))}
          </ol>
        </div>
      </div>
     {flag?<></>:<img src={image} alt={name} className={styles.founderImage} />}

    </div>
  );
};



const FoundersSection = () => {
  return (
    <div className={styles.main}>
    <div className={styles.container}>
    <div data-aos="fade-down">
      <Header 
      header={"Meet the Founders"}
      smallMessage={"A Shared Vision: The Birth of HealthMates.AI"}
      bigMessage={"The journey of HealthMates.AI began with two industry leaders—Rahul Bajaj and Shitiz Ruhail—who shared a vision to bridge the gap between technology and healthcare. Having worked extensively across healthcare strategy, AI, and business transformation, they witnessed firsthand how hospitals struggled with staff shortages, administrative overload, and fragmented patient experiences. This realization led them to reimagine healthcare operations with AI-powered teammates, giving birth to HealthMates.AI."}
      />
      </div>
      <Founder
        name="Rahul Bajaj"
        title="Founder"
        description="A technologist at heart, Rahul has been at the forefront of AI-driven transformation in healthcare, IT, and business automation. From Fortis Healthcare to Sutherland and Birlasoft, he spearheaded large-scale digital transformation initiatives that reduced inefficiencies and improved financial outcomes. However, he knew that automation alone wasn’t enough—healthcare needed AI teammates that could think, learn, and execute like real employees. This belief laid the foundation for HealthMates.AI, bringing AI-powered intelligence into healthcare operations."
        experience={["B.Tech | MS (BITS Pilani) | MBA (Corp. Strategy, FMS)", "Ex-Fortis, Sutherland, DiGiSPICE, Birlasoft", "20+ years of expertise in AI, automation & healthcare transformation"]}
        image={rahul}
        flag={true}
      />

      <Founder
        name="Shitiz Ruhail"
        title="Co-Founder"
        description="With a deep-rooted passion for AI-driven healthcare strategy, Shitiz has spent his career helping hospitals leverage technology to improve patient care and operational efficiency. During his tenure at Tech Mahindra, The HCI Group, and Continental Hospitals, he saw the inefficiencies that drained resources and impacted care quality. This inspired him to co-found HealthMates.AI, ensuring hospitals could access AI teammates capable of reducing workload and improving patient experiences."
        experience={["MBA (Finance & Marketing, ISB)", "Ex-Tech Mahindra, The HCI Group, Continental Hospitals", "Healthcare AI Strategy & Business Growth Expert"]}
        image={shitiz}
        flag={window.innerWidth<=768?true:false}
      />
      
      <div className={styles.footerSection}>
        <p className={styles.together}>Together, Rahul and Shitiz built HealthMates.AI to be more than just an AI solution—it’s a healthcare revolution. Their combined expertise ensures that AI is not just an assistant, but an integral part of modern healthcare teams.</p>
      </div>

    </div>
    <div data-aos="fade-up">
      <FlexContainer />
      </div>
    </div>
  );
};

export default FoundersSection;
