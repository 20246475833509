import React from "react";
import styles from "../../styles/main.module.css";
const SmallFeatureCard = ({
  img = "",
  title = "",
  subTitle = "",
  i = null,
  isKeyDifference = false,
  isCenter = false,
}) => {
  return (
    <div
      className={`${styles?.["flex-column"]}  `}
      style={{ justifyContent: "center" }}
    >
      <div
        style={{ alignItems: isCenter ? "center" : "start" }}
        className={`${styles?.["feature-black-card"]}  `}
      >
        <div style={{ minHeight: "70px" }}>
          <div
            style={{
              width: "20%",
              justifyContent: "end",
              maxWidth: "50px",
              minWidth: "50px",
            }}
            className={` ${styles?.["flex-gap"]}  `}
          >
            {/* <img style={{ width:"100%",padding:i==1 ? "13rem 0rem":i==2?"2rem 0rem":i==3?"4rem 0rem":"0" }} src={img} alt="" /> */}
            <img width="100%" src={img} alt="" />
          </div>
        </div>

        <div className={` ${styles?.["left"]} ${styles?.["feature-content"]}`}>
          <div className={`${styles?.["head-sm"]} ${styles?.[isCenter?"center":""]} `}><b>{title}</b></div>
          <div
            style={{ fontWeight: "400" }}
            className={`${styles?.["head-xsm"]}  ${styles?.["p-t-1"]}`}
          >
            {subTitle}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SmallFeatureCard;
