import React, { useState } from "react";
import styles from "../../styles/main.module.css";
const ListCard = ({
  isGradiant = false,
  isKeyDifference = false,
  data
}) => {


  return (
    <div
  
      className={`${
        styles?.[isGradiant ? "feature-card-gradiant" : "feature-card"]
      } ${styles[!isKeyDifference ? "" : ""]}  `}
    >
        <div className={`${styles['flex-end']} ${styles['w-100']}`}>
      <div
        style={{
          maxWidth: "50px",
          minWidth: "50px",
          float:'left'
        }}
        className={`${styles?.["w-80"]}  `}
      >
        <img width="100%" src={data?.img} alt="" />
      </div></div>

      <div className={` ${styles?.["feature-content"]}`}>
        <div
          className={`${styles?.["head-md-sm"]}`}
        >
          <b>{data?.title}</b>
        </div>
        <ul>
{
    data?.list?.map((item)=>(
        <li
        style={{ lineHeight:"1rem" }}
        // style={{ fontWeight: "300", fontSize: ".8rem" }}
        className={` ${styles?.["fade-content2"]}   ${styles?.["p-t-1"]}
    ${styles?.["font-w-4"]}`}
      >
        {item}
      </li>
    ))
}
       
        </ul>
      </div>
    </div>
  );
};
export default ListCard;
