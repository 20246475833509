import React from "react";
import styles from "../../styles/main.module.css";
import cover from "../../assets/Home Page/featureRing.png";
import coverVideo from "../../assets/Home Page/featuresVideo.mp4";

import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
import { mobileDimension } from "./CardSelector";

const FeaturesRing = () => {
  const isMobile = useMediaQuery(mobileDimension);
  return (
    <div className={styles.wrap}>
      <div style={{ gap: "5px" }} className={`${styles?.["flex-column"]} `}>
        <div className={` ${styles?.["head-lg"]} ${styles?.["center"]} `}>
          Brought in by path breaking <br />multi-agentic architecture
        </div>
        <Spacer height={"2rem"} />
        <div
          className={` ${styles?.["head-sm"]} ${styles?.["font-w-4"]} ${styles?.["center"]} ${styles?.[isMobile?"w-80":""]} `}
        >
          {/* A human-capable, safety-focused, non-diagnostic,
          {!isMobile?<br />:<></>} healthcare tuned Artificial General Intelligence like system */}
        </div>
        <div style={{ width: isMobile ? "100%" : "56%" }}>
          {/* <img width="100%" src={cover} alt="" /> */}
          <video  width="100%"  muted  playsInline autoPlay loop >
            <source  src={coverVideo} type="video/mp4"/>
          </video>
        </div>
      </div>
    </div>
  );
};
export default FeaturesRing;
