import React, { useState } from "react";
import styles from "../../styles/main.module.css";
import star from "../../assets/Home Page/icons/graidant-star.png";
import heart from "../../assets/Home Page/icons/gradient-heart.png";
import money from "../../assets/Home Page/icons/gradiant-money.png";
import users from "../../assets/Home Page/icons/gradient-users.png";
import FeatureCard from "./FeatureCard";
import Spacer from "../common/Spacer";
import { mobileDimension, tabletDimension } from "./CardSelector";
import { useMediaQuery } from "react-responsive";

const KeyDifferences = ({ data, isGradiant = false }) => {
  const isMobile = useMediaQuery(mobileDimension);
  const isTablet = useMediaQuery(tabletDimension);
  return (
    <div
      className={`${styles["flex-center"]} ${styles[isMobile ||isTablet ? "wrap" : ""]} ${
        styles["m-auto"]
      } ${styles["w-90"]}`}
    >
      <div className={`${styles[isMobile || isTablet ? "w-100" : "w-40"]} `}>
        <Spacer height={isMobile ? "1rem" : "3rem"} />
        <div
          className={`${styles[isMobile ? "center" : "left"]} ${
            styles["head-md-lg"]
          } ${styles["w-100"]} `}
        >
          {data?.title}
        </div>
        <Spacer height={isMobile?"2rem":"3rem"} />
        <div
          style={{
            // fontSize: "1.1rem",
            lineHeight: "1.6",
          }}
          className={`${styles[isMobile ? "head-xsm" : "head-sm"]}  ${
            styles[isMobile ? "center" : ""]
          } ${styles["font-w-4"]}`}
        >
          {data?.desc}
        </div>
      </div>
      <div
        className={`${styles["key-grid"]} ${styles[isMobile ? "wrap" : ""]} ${
          styles[isMobile ||isTablet ? "w-80" : "w-50"]
        }`}
      >
        {/* {
          data?.features?.map((feature,i)=>(
            <div style={{transform:`translateY(${!isMobile?i==1?"20px":i==2?"10px":i==3?"30px":"0":"0"})`, }}>
            <FeatureCard
            title={feature?.title}
            img={feature?.img}
            isKeyDifference={true}
            subTitle={feature?.subTitle}
          />
          </div>
          ))
        } */}
      {isMobile||isTablet?<Spacer height={".8rem"}/>:<></>}

        <div>
          <FeatureCard
            title={data?.features[0]?.title}
            img={data?.features[0]?.img}
            isKeyDifference={true}
            subTitle={data?.features[0]?.subTitle}
            isGradiant={isGradiant}
          />
          <Spacer height={"1.2rem"} />
          <FeatureCard
            title={data?.features[2]?.title}
            img={data?.features[2]?.img}
            isKeyDifference={true}
            isGradiant={isGradiant}
            subTitle={data?.features[2]?.subTitle}
          />
        </div>
        <div style={{ marginTop: isMobile ||isTablet ? "0rem" : "3rem" }}>
          <FeatureCard
            title={data?.features[1]?.title}
            img={data?.features[1]?.img}
            isKeyDifference={true}
            isGradiant={isGradiant}
            subTitle={data?.features[1]?.subTitle}
          />

          <Spacer height={"1.2rem"} />

          <FeatureCard
            title={data?.features[3]?.title}
            img={data?.features[3]?.img}
            isGradiant={isGradiant}
            isKeyDifference={true}
            subTitle={data?.features[3]?.subTitle}
          />
        </div>
      </div>
    </div>
  );
};
export default KeyDifferences;
