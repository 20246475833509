import React, { useEffect, useState } from "react";
import styles from "../styles/main.module.css";
import Spacer from "../components/common/Spacer";
import { add_subscription } from "../api/Form/FormApi";
import Error from "../components/common/Error";
import Success from "../components/common/Success";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
const Subscribe = () => {
    const isMobile = useMediaQuery({ maxWidth: "900px" });
    const navigate=useNavigate()
    const [formData,setFormData]=useState({
        email:"",
        flag:1
    })
      const [isSuccess, setIsSuccess] = useState(false);
      const [isError, setIsError] = useState(false);
    
   const handleInputChange=(e)=>{
    const {name,value}=e.target
    setFormData({...formData,[name]:value})
   }
   const handleSubmit=async(e)=>{
    e.preventDefault()
    const res=await add_subscription(formData);
    // console.log(formData)
    console.log(res)
    if (res) {
        setIsSuccess(true);
        setFormData({
          email:"",
          flag:1
        });
      } else {
        setIsError(true);
      }
    console.log(formData,res)
   }
    

  return (
    <>
    {isSuccess ? (
        <>
          <Success text="Your information has been recorded successfully" />
        </>
      ) : isError ? (
        <Error />
      ) : (
        <div
        style={{ gap: "1rem" }}
        className={`${styles.subscribe} ${styles.center} ${styles["flex-column"]}  ${styles[""]}`}
      >
        <div className={styles["head-lg"]}>
        Sign-up for whitelist, <br />
Be the first to get access to <br /> create your own HealthMates


        </div>
        <Spacer height={"1rem"} />

        <div className={`${styles["head-md"]} ${styles["font-w-4"]}`}>
        Stay Ahead with the Latest Innovations
        </div>
        <Spacer height={"1rem"} />

        <form
          onSubmit={handleSubmit}
          className={` ${styles["flex-gap"]} ${styles[isMobile?"flex-column":"flex-gap"]}   ${styles["subscribe-mail-container"]}`}
        >
          <input
          required
            className={styles["subscribe-inpt"]}
            onChange={handleInputChange}
            type="email"
            placeholder="Email address"
            name="email"
            id=""
          />{" "}
          {
            isMobile?<Spacer height={"2rem"}/>:<></>
          }
          <input type="submit" value="Notify Me" className={styles["subscribe-button"]}/>
        </form>
        <Spacer height={"3rem"} />
      <Link style={{ color:"white" }} to={"/partners"} >Know why to partner with us</Link>
      </div>
      )}
        <Spacer height={"2rem"} />
      
      
    </>
  );
};
export default Subscribe;
