import React, { useEffect, useState } from "react";
import styles from "./ProfileData.module.css";
import mainStyles from "../../styles/main.module.css"
import ritik from "../../assets/Home Page/ritik.png";
import Ana from "../../assets/Home Page/hojaMed.png";

import data from "../../data/db.json"
import Spacer from "../common/Spacer";
import { useNavigate } from "react-router-dom";

const tabs = [
  {
    name: "Patient Experience",
    description: "Increase Patient Experience by 10%",
  },
  {
    name: "Operations Efficiency",
    description: "Increase Operation Efficiency by 10%",
  },
  { name: "Revenue Enhancement", description: "Enhance Revenue by 10%" },
];

export const initialHealthmatesData = [
];

const ProfileData = () => {
  const navigate=useNavigate();
  const botData=JSON.parse(localStorage.getItem("botDetails"))
  const [shownIndex,setShownIndex]=useState(1)
  const [filteredBots,setFilteredBots]=useState([])
  const [activeTab, setActiveTab] = useState(botData?.category||0);
  const [healthmatesData, setHealthmatesData] = useState(
    initialHealthmatesData
  );

  const   loadMore = () => {
    setShownIndex(prev=>prev+1)
  };
  useEffect(()=>{
  setShownIndex(1)

  if(botData?.category==activeTab){
    setFilteredBots([botData,...data?.filter((bot)=>bot?.category==activeTab&&bot?.id!=botData?.id)])
  }
  else{
  setFilteredBots(data?.filter((bot)=>bot?.category==activeTab))
  }
  // localStorage.clear()
  },[activeTab])

  return (
    <div className={styles.container}>
      <h1 className={styles.leftalign}>Healthmates Profile Page</h1>
      <div className={`${mainStyles["gradiant-tabs"]} ${mainStyles.wrap} `}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            style={{
              flexDirection: "column",
              borderRadius: "1rem",
              minWidth: "300px",
              padding: "1rem",
            }}
            className={
              mainStyles[activeTab == index ? "white-button" : "outline-button"]
            }
           
            onClick={() => setActiveTab(index)}
          >
            <div className={styles.title}>{tab.name}</div>
            {/* <p>{tab.description}</p> */}
            {/* <div className={styles['head-sm']}>{}</div> */}
          </div>
        ))}
      </div>
      <Spacer height={"2rem"}/>

      <div className={styles.cardsContainer}>
        {filteredBots?.filter((bot,i)=>i<shownIndex*3).map((person, index) => (
          <div key={index} className={styles.card} data-aos="fade-up">
            <img
              src={person?.image}
              alt={person.name}
              className={styles.image}
            />
            <h2 className={styles.name}>{person.name}</h2>
            <p className={styles.role}>{person.role}</p>
            <p className={styles.roleTitle}>
              <strong>Role:</strong>
            </p>
            <p className={styles.description}> {person.description}</p>
            <p className={styles.replacedRole}>
              <strong>Replaced Role:</strong> {person.replacedRole}
            </p>
            <div className={styles.details}>
              {person.details.map((detail, i) => (
                <span key={i} className={styles.detail}>
                  Task Time {i===0?"Human":person.name}: {detail}
                </span>
              ))}
            </div>
            <button className={styles.tryNow} onClick={()=>navigate("/contactus")}>Try Now</button>
          </div>
        ))}
      </div>
      {
        filteredBots?.length>3*shownIndex?<button className={styles.loadMore} onClick={loadMore}>
        Load More HealthMates
      </button>:<></>
      }

      <Spacer height={"1rem"}/>
      
    </div>
  );
};

export default ProfileData;
