import React, { useEffect, useState } from "react";
import styles from "../../styles/main.module.css";
import animation from "../../styles/animation.module.css";
import { IoMdCloseCircleOutline } from "react-icons/io";
import FeaturesWithCard from "../common/FeaturesWithCard";
import nurse from "../../assets/Home Page/nurse - Copy.png";
import prescripition from "../../assets/Home Page/prescripition.png";
import bug from "../../assets/Home Page/bug.png";
import bag from "../../assets/Home Page/bag.png";
import shipping from "../../assets/Home Page/icons/shipping.png";
import user3 from "../../assets/Home Page/icons/user3.png";
import nurse2 from "../../assets/Home Page/icons/nurse2.png";
import nurse3 from "../../assets/Home Page/icons/nurse-3.png";
import tree from "../../assets/Home Page/icons/tree.png";
import decline from "../../assets/Home Page/icons/decline.png";
import settings from "../../assets/Home Page/icons/settings.png";
import hospital from "../../assets/Home Page/icons/hospital.png";
import serachUsers from "../../assets/Home Page/icons/search-user.png";
import clock from "../../assets/Home Page/icons/clock.png";
import trading from "../../assets/Home Page/icons/trade.png";
import wellness from "../../assets/Home Page/icons/wellness.png";
import userSettings from "../../assets/Home Page/icons/user-settings.png";

import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
import { mobileDimension, tabletDimension } from "./CardSelector";
import ListCard from "./ListCard";

const RevolutionModal = ({ isOpen, setIsOpen }) => {
  const isMobile = useMediaQuery(mobileDimension);
  const isTab =useMediaQuery(tabletDimension)
  // const [isOpen,setIsOpen]=useState(false)
  const featuresData = {
    // title:"The Next Evolution: Build Your Own HealthMate",
    // desc:"Soon, we will introduce a revolutionary AI configurator, allowing healthcare providers to:",
    features: [
      {
        title: "Administrative Burdens",
        img: shipping,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
      {
        title: "Overwhelmed Doctors",
        img: nurse2,
        // subTitle:"Monetize custom-built AI solutions and make them available for adoption worldwide.",
      },
      {
        title: "Manual workflows",
        img: tree,
        // subTitle:" No coding required! Deploy AI teammates in days, not months.",
      },
      {
        title: "Inefficient Processes",
        img: decline,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
      {
        title: "Multiple IT Systems",
        img: settings,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
      {
        title: "Staffing Shortages",
        img: serachUsers,
        // subTitle:"Monetize custom-built AI solutions and make them available for adoption worldwide.",
      },
      {
        title: "Long Wait Times",
        img: clock,
        // subTitle:" No coding required! Deploy AI teammates in days, not months.",
      },
      {
        title: "High Churn Rate",
        img: trading,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
      {
        title: "Low Skilled Pool",
        img: userSettings,
        // subTitle:" No coding required! Deploy AI teammates in days, not months.",
      },
      {
        title: "Dropping Care Quality",
        img: wellness,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
    ],
  };
  const featuresData2 = {
    // title:"The Next Evolution: Build Your Own HealthMate",
    // desc:"Soon, we will introduce a revolutionary AI configurator, allowing healthcare providers to:",
    features: [
      {
        title: "Global shortfall of 10 million nurses",
        img: nurse,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
      {
        title: "Spending more on administrative tasks.",
        img: prescripition,
        // subTitle:"Monetize custom-built AI solutions and make them available for adoption worldwide.",
      },
      {
        title: "Hospitals on an average use three or more IT systems ",
        img: bug,
        // subTitle:" No coding required! Deploy AI teammates in days, not months.",
      },
      {
        title: "Care costs going up & care quality taking a hit",
        img: bag,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
    ],
  };
  const featuresData3 = {
    // title:"The Next Evolution: Build Your Own HealthMate",
    // desc:"Soon, we will introduce a revolutionary AI configurator, allowing healthcare providers to:",
    features: [
      {
        title: "Global shortfall of 10 million nurses",
        img: user3,
        list: [
          "India: Wait time 60–120 minutes, consultation time < 10 minutes. (WHO, 2024) ",
          "Middle East: 25% delay in treatment due to poor scheduling. (McK, 2023)",
          "Global: Missed or delayed diagnosis affects 1 in 10 patients. (BMJ, 2024)",
        ],
      },
      {
        title: "Spending more on administrative tasks.",
        img: hospital,
        // subTitle:"Monetize custom-built AI solutions and make them available for adoption worldwide.",
        list: [
          "Efficiency: Bed utilization in India below 60%. (NITI Aayog, 2023)",
          "Accuracy: Manual scheduling errors cost ₹5–7 crore annually. (EY, 2024)",
          "Revenue: 30% potential revenue loss due to inefficiencies. (Deloitte, 2024)",
        ],
      },
      {
        title: "Hospitals on an average use three or more IT systems ",
        img: nurse3,
        list: [
          "Admin Overload: Doctors spend 26% of their time on paperwork. (WHO, 2024)",
          "Faster Burnout: Burnout is at an all-time high, > 30% turnover rates. (HBR, 2024)",
          "Stress & Fatigue: 1 in 3 Nurses plans to quit in 5 yrs. (Indian Nursing Council, 2023)",
        ],
        // subTitle:" No coding required! Deploy AI teammates in days, not months.",
      },
    ],
  };
  useEffect(()=>{
 if(isOpen){
  document.body.style.overflow="hidden"
 }else{
  document.body.style.overflow=""
  
 }
 return()=>{
  document.body.style.overflow=""

 }
  },[isOpen])
  return (
    <>
      {isOpen ? (
        <>
          <div className={styles.overlay}>
            <div
              className={`${styles.RevolutionModal} ${
                animation[
                  isOpen
                    ? "slide-in-elliptic-bottom-fwd"
                    : "slide-out-elliptic-bottom-fwd"
                ]
              }`}
            >
              <div
                onClick={() => setIsOpen(false)}
                className={styles.modalCrossButton}
              >
                <IoMdCloseCircleOutline size={33} />
              </div>
              <div style={{ gap: "3rem" }} className={styles["flex-column"]}>
                <div className={""}>
                  <div className={` ${styles["head-lg"]}`}>
                    Healthcare needs a Revolution
                  </div>
                  {/* <Spacer height={"2rem"} /> */}
                  <div className={styles[""]}>
                    <FeaturesWithCard
                      // isFlip={true}
                      isCenter={true}
                      min_height="180px"
                      fullWidth={true}
                      grid={isMobile ? 1 :isTab?2: 4}
                      data={featuresData2}
                      backgroundColor={"#4E4D4D"}
                    />
                  </div>
                </div>
                <div className={""}>
                  <div className={`${styles["head-lg"]} ${styles["w-90"]}`}>
                    A Broken System Hurts Everyone
                  </div>
                  <Spacer height={"2rem"} />
                  <div
                    style={{
                      gap: "1rem",
                      gridTemplateColumns: `repeat(${isMobile?1:isTab?2:3},1fr)`,
                      width: "100%",
                      margin: "auto",
                    }}
                    className={styles["feature-grid"]}
                  >
                    {featuresData3?.features?.map((feature, i) => (
                      <ListCard isGradiant={true} data={feature} />
                    ))}
                  </div>
                </div>
                <div
                  style={{ background: "#575757" }}
                  className={`${styles["cover-opex"]}`}
                >
                  <div
                    className={` ${styles["head-md-lg"]}`}
                  >
                    By 2030, AI in healthcare is projected to reduce Hospital
                    OPEX by 30% and replace 12Mn jobs...
                  </div>
                  {/* <Spacer height={"2rem"} /> */}
                  <div className={styles[""]}>
                    <FeaturesWithCard
                      // isFlip={true}
                      fullWidth={true}
                      min_height="150px"
                      isCenter={true}
                      grid={isMobile ? 1 :isTab?3: 5}
                      data={featuresData}
                      backgroundColor={"#4E4D4D"}
                    />
                  </div>
                </div>
                <div className={styles['cover-outline']}>
                  <div className={styles['head-sm']}>AI can help solve Healthcare Sector’s critical challenges... But, for real impact</div>
                  <div style={{ fontWeight:"700" }} className={`${styles['head-xlg']} ${styles['gradiant-pink-col']}`}>
                  AI needs to be supercharged
                  </div>
                  <div className={`${styles['head-md']} ${styles['center']} ${styles['w-80']} ${styles['m-auto']}`}>
                  It needs to be capable of not just automating a task from the workflow, but own the workflow and its outcomes!
                  </div>

                </div>
              </div>
            </div>{" "}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default RevolutionModal;
