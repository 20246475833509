import React from "react";
import cover from "../../assets/Home Page/pixel_banner_design_3112 [Converted] 1.png";
import coverMobile from "../../assets/Home Page/pixel_banner_design_3112 [Converted] 2.png";

import styles from "../../styles/main.module.css";
import { useNavigate } from "react-router-dom";
import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
import { mobileDimension, tabletDimension } from "./CardSelector";

const CoverWithDescription = ({setIsOpen}) => {
  const navigate = useNavigate();
  const isMobile=useMediaQuery(mobileDimension)
  const isDesktop=useMediaQuery(tabletDimension)


  return (
    <div className={styles.cover}>
      <div>
        <img width="100%" src={isMobile?coverMobile:cover} alt="" />
      </div>
      <div
        style={{
          background: "rgb(255 255 255 / 9%)",
          marginTop: "0rem",
          height: "100%",
          gap: "1.5rem",
        }}
        className={`${styles["cover-content"]} ${styles["center"]} ${styles?.["flex-column"]}`}
      >
        <div className={` ${styles?.["head-lg"]} ${styles?.["center"]} `}>
        Because Healthcare needs a Revolution......<br />
        {/* A revolution called HealthMates  */}
        </div>

        <div  className={` ${styles?.["head-sm"]} ${styles?.["line-h-1"]} ${styles?.["font-w-4"]} ${styles?.["center"]} `}>
          Shortage of staff, fragmented systems,
          <br /> and inefficiencies are crippling healthcare
        </div>

        <button
          onClick={() => setIsOpen(true)}
          className={`${styles?.["white-button"]} ${styles?.["head-sm"]} ${styles?.["primary-color"]} ${styles?.["req-button"]}`}
        >
         Know More
        </button>
      </div>
    </div>
  );
};
export default CoverWithDescription;
