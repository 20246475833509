import React from "react";
import styles from "../../styles/main.module.css";
import logo from "../../assets/healthmates-new-logo.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
  const navigate = useNavigate();
  const isMobileOrTab = useMediaQuery({ maxWidth: "900px" });

  return (
    <div className={`${styles?.["flex-between"]} ${styles.block} ${styles?.["footer"]}`}>
      <div className={`${styles?.["flex-gap"]} ${styles?.["mobileWithNone"]} ${styles?.["w-50"]}  `}>
      <div
          
          className={`${styles?.["head-md"]} ${styles?.["gradiant-pink-col"]} ${styles.pointer} ${styles?.[""]} `}
        >
          HealthMates.AI
        </div>
        <div   style={ isMobileOrTab?{fontSize:".8rem"}:{ width: "60%" }}></div>
      </div>
      <div style={isMobileOrTab?{}:{ gap:"1rem",width:"34%" }} className={`${styles?.["flex-between"]} ${styles?.[""]}`}>
        <div className={`${styles?.["flex-between"]} ${styles?.[""]}`}>
          <div
          style={ isMobileOrTab?{fontSize:".8rem"}:{ width: "100%" }}
            onClick={() => {
              window.open("https://www.digitaldarwin.ai/privacy", "_blank");
            }}
            link="https://www.digitaldarwin.ai/privacy"
            className={` ${styles?.["pointer"]}`}
          >
            Privacy Policy
          </div>
          {/* <div className={` ${styles?.["pointer"]}`} > Contact us</div>
                <div className={` ${styles?.["pointer"]}`}> Research</div> */}
        </div>
        <div
          className={`${styles?.["flex-gap"]} ${styles?.["primary-color"]} `}
        >
          <div className={` ${styles?.["pointer"]}`}>
            {" "}
            <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
            <svg
              width="30"
              height="36"
              viewBox="0 0 44 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M39.6 0H4.4C1.98 0 0.022 2.025 0.022 4.5L0 31.5C0 33.975 1.98 36 4.4 36H39.6C42.02 36 44 33.975 44 31.5V4.5C44 2.025 42.02 0 39.6 0ZM39.6 9L22 20.25L4.4 9V4.5L22 15.75L39.6 4.5V9Z"
                fill="white"
              />
            </svg>
            </a>
          </div>
          <div
          // contact@healthmates.ai
            onClick={() =>
              window.open("https://www.linkedin.com/showcase/healthmates-ai/", "_blank")
            }
            className={` ${styles?.["pointer"]}`}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.7778 0C34.8976 0 35.9715 0.44484 36.7633 1.23666C37.5552 2.02848 38 3.10242 38 4.22222V33.7778C38 34.8976 37.5552 35.9715 36.7633 36.7633C35.9715 37.5552 34.8976 38 33.7778 38H4.22222C3.10242 38 2.02848 37.5552 1.23666 36.7633C0.44484 35.9715 0 34.8976 0 33.7778V4.22222C0 3.10242 0.44484 2.02848 1.23666 1.23666C2.02848 0.44484 3.10242 0 4.22222 0H33.7778ZM32.7222 32.7222V21.5333C32.7222 19.7081 31.9971 17.9575 30.7065 16.6669C29.4158 15.3762 27.6653 14.6511 25.84 14.6511C24.0456 14.6511 21.9556 15.7489 20.9422 17.3956V15.0522H15.0522V32.7222H20.9422V22.3144C20.9422 20.6889 22.2511 19.3589 23.8767 19.3589C24.6605 19.3589 25.4123 19.6703 25.9666 20.2246C26.5208 20.7788 26.8322 21.5306 26.8322 22.3144V32.7222H32.7222ZM8.19111 11.7378C9.13175 11.7378 10.0339 11.3641 10.699 10.699C11.3641 10.0339 11.7378 9.13175 11.7378 8.19111C11.7378 6.22778 10.1544 4.62333 8.19111 4.62333C7.24488 4.62333 6.3374 4.99922 5.66831 5.66831C4.99922 6.3374 4.62333 7.24488 4.62333 8.19111C4.62333 10.1544 6.22778 11.7378 8.19111 11.7378ZM11.1256 32.7222V15.0522H5.27778V32.7222H11.1256Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
