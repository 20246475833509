import React from "react";
import styles from "../../styles/main.module.css";
import bot from "../../assets/Home Page/chat-bot-outline.png";
import whiteLine from "../../assets/linewhite-background.png";
import logo1 from "../../assets/Marketing Logos/image1.png";
import logo2 from "../../assets/Marketing Logos/image2.png";
import logo3 from "../../assets/Marketing Logos/image3.png";
import logo4 from "../../assets/Marketing Logos/image4.png";
import logo5 from "../../assets/Marketing Logos/image5.png";
import logo6 from "../../assets/Marketing Logos/image6.png";
import logo7 from "../../assets/Marketing Logos/image7.png";
import logo8 from "../../assets/Marketing Logos/image8.png";
import logo9 from "../../assets/Marketing Logos/image9.png";
import logo10 from "../../assets/Marketing Logos/image10.png";
import logo11 from "../../assets/Marketing Logos/image11.png";
import logo12 from "../../assets/Marketing Logos/image12.png";
import logo13 from "../../assets/Marketing Logos/image13.png";
import logo14 from "../../assets/Marketing Logos/image14.png";
import logo15 from "../../assets/Marketing Logos/image15.png";


import Typewriter from "../common/TypeWriter";
import VoiceToText from "../common/Voice";
import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
const MainCover = () => {
  const isMobile = useMediaQuery({ maxWidth: "900px" });
  const brands = [
    { width: "50px", image: logo1 },
    { width: "100px", image: logo2 },
    { width: "150px", image: logo3 },
    { width: "130px", image: logo4 },
    { width: "160px", image: logo5 },
    { width: "258px", image: logo6 },
    { width: "130px", image: logo7 },
    { width: "50px", image: logo8 },
    { width: "120px", image: logo9 },
    { width: "200px", image: logo10 },
    { width: "100px", image: logo11 },
    { width: "100px", image: logo12},
    { width: "100px", image: logo13 },
    { width: "120px", image: logo14 },
    { width: "140px", image: logo15 },


   
  ];
  const navigate = useNavigate();
  const sentences = [
    "  Helping you re-imagine patient experience​",
    " Streamlining your Hospital Workflows​",
    " Enhancing your Staff Productivity by Automating Routine Tasks​​",
    " Becoming force multipliers overcoming staff shortage​",
    " Empowering your Staff to Focus on Critical Patient Care​​",
    " Available 24X7, an infinitely scalable cost optimized workforce​",
    " Elevating healthcare delivery standards​​",
    " Improving patient experience​​",
  ];
  return (
    <div
      // style={{ minHeight: "49.0625rem" }}
      className={`${styles['cover-home']} `}
    >
      {/* <div style={{ minHeight:"6rem" }}></div> */}
      <div style={{ gap:"1.5rem" }} className={`${styles?.["flex-column"]} `}>
          <div
            className={` ${styles?.["head-lg"]}   ${styles?.["center"]} `}
          >
           Re-Imagine Healthcare
          </div>
        

          <div
            // style={{ minHeight: "30px" }}
            className={`${styles?.["head-sm"]}   ${styles?.["center"]} ${styles?.["font-w-4"]}  ${
              styles?.["m-auto"]
            }  `}
          >
            Incredibly Human AI Digital Workers - Healthcare AI TeamMates -<br/>
            designed to transform hospital operations and patient care.
            {/* <Typewriter sentences={sentences} /> */}
          </div>
          {/* <div style={{ width:"30%" }}>
            <img width="100%" src={bot} alt="" />
          </div> */}
          <VoiceToText/>
          {/* <Marquee autoFill={false} className={styles['brand-list']}   speed={50} direction="left">
           
          {brands.map((data,index) => (
            <div
              // onClick={() => window.open(data.link, "_blank")}
              style={{ margin: "0rem 1rem", width: "fit-content"}}
              className={`${styles?.["media-grid-item"]} ${styles?.["flex-column"]}  `}
            >
              <img style={{ width: data?.width}} src={data?.image} alt="" />
            </div>
          ))}
          
          
        </Marquee> */}
         
        
          
    
        </div>
        <div className={styles['marquee-container']} >
            <div  className={`${styles?.["marquee"]}   `}>
          {[...Array(5)].map(() =>brands.map((data,index) => (
            <span
              // onClick={() => window.open(data.link, "_blank")}
              style={{ margin: "0rem 1rem ", width: "fit-content"}}
             
            >
              <img style={{ width: data?.width}} src={data?.image} alt="" />
            </span>
          )))}</div>
          </div>
    </div>
  );
};
export default MainCover;
