import React from "react";

import styles from "../styles/main.module.css";
import scientist from "../assets/About Us/scientist.png";
import multiple from "../assets/About Us/multiple-face.png";
import Spacer from "../components/common/Spacer";
import FlexContainer from "../components/home/FlexContainer";
import MainCover from "../components/about/MainCover";
import GlobalShortfall from "../components/about/GlobalShortfall";
import ValuesSection from "../components/about/ValuesSections";
import NewsFeed from "../components/common/NewsFeed";
import { useMediaQuery } from "react-responsive";
import { mobileDimension } from "../components/home/CardSelector";

const About = () => {
  const isMobile = useMediaQuery(mobileDimension);
  const features = [
    {
      title:"Create Your Own HealthMate",
      name: " Customize AI teammates with pre-trained healthcare micro-agents.",
    },
    {
      title:"Commercialize on the HealthMates Store",
      name: " Innovators can not only build AI teammates but also share and monetize their solutions via our HealthMates Store, enabling healthcare providers worldwide to access battle-tested AI-driven workflows.",
    },
    {
      title:"Rapid Deployment & Scalability –",
      name: "Whether you are a hospital administrator or a health-tech entrepreneur, our intuitive interface ensures that you can launch AI-powered teammates in days, not months.With HealthMates.AI, we are building more than AI teammates—we are creating the future of AI-driven healthcare innovation.",
    },
  ];
  const Achieve = [
    {
      title: "✅ Enhance Patient Experience-",
      description:
        " AI-driven scheduling, triage, concierge support, and post-care follow-ups to reduce wait times and increase accessibility.",
    },
    {
      title: " ✅ Drive Operational Efficiency – ",
      description:
        "Automated clinical documentation, medical coding, nursing handovers, and hospital workflows to free up human resources.",
    },
    {
      title: "✅ Create Revenue Enhancement –",
      description:
        "   AI-powered insurance claims processing, appointment optimization, and data-driven decision-making to improve financial outcomes.",
    },
  ];

  const values = [
    {
      title: "Our Mission",
      summary:
        "Healthcare faces a crisis of staff shortages, rising costs, and inefficiencies that strain both patients and providers. HealthMates.AI is the answer—a workforce of AI-powered teammates capable of transforming how healthcare institutions operate.",
      summary2:
        "Our mission is to empower healthcare providers with AI that listens, learns, acts, and adapts, ensuring every decision is data-backed and patient-centric.",
      img: multiple,
    },
    {
      title: "Our Vision",
      summary:
        "Imagine a future where AI-driven healthcare is accessible to all, not just through pre-built AI teammates but through an intelligent, modular AI ecosystem that you can shape and customize to your needs. HealthMates.AI is not just about providing AI workers—it’s about democratizing AI in healthcare.",
      summary2:
        "Soon, we will introduce a game-changing AI configurator, allowing hospitals, clinics, and innovators to design, connect, and deploy their own AI-powered HealthMates. This no-code, drag-and-drop AI builder will let users harness fine-tuned micro-agents and pre-made functionalities to create AI teammates tailored to their unique requirements.",
      img: scientist,
    },
  ];
  return (
    <div
      data-aos="fade-down"
      style={{ gap: isMobile ? "2rem" : "5rem" }}
      className={`${styles?.["flex-column-str"]}  `}
    >
      <MainCover
        title={
          "Revolutionizing Healthcare with AI-Driven Intelligence At HealthMates.AI"
        }
        subTitle1="We are leading the next era of healthcare by creating AI-powered teammates—intelligent, autonomous digital workers that seamlessly integrate into hospital operations to enhance patient care, streamline workflows, and optimize financial outcomes. "
        subTitle2="HealthMates.AI represents a fundamental shift in healthcare AI. Our AI teammates are not mere automation tools; they are adaptive, multimodal, and deeply integrated AI professionals capable of working alongside human staff to drive efficiency, accuracy, and innovation in hospitals and clinics worldwide."
      />

      {/* <div data-aos="fade-right"><ValuesSection /></div> */}
      <div
        data-aos="fade-left"
        style={{ gap: "4rem" }}
        className={` ${styles?.["flex-column-str"]} `}
      >
        {values?.map((news, i) => (
          <NewsFeed
            isLeft={isMobile ? false : i / 2 == 0}
            title={news.title}
            img={news.img}
            summary={news.summary}
            summary2={news.summary2}
          />
        ))}
      </div>
      <div
        data-aos="fade-right"
        className={`  ${styles?.[isMobile ? "w-90" : "w-80"]} ${
          styles?.["m-auto"]
        }  `}
      >
        {features?.map((f, index) => (
          <div
            style={{ alignItems: "start", textAlign: "left", gap: "2rem" }}
            className={` ${styles?.[isMobile?"head-xsm":"head-sm"]} ${styles?.[""]} ${styles?.["m-t-3"]} ${styles?.["line-h-2"]}   ${styles["list-background"]}   ${styles["font-w-4"]}`}
          >
            <div
              style={{ border: "none", padding: ".8rem", fontSize: "1rem" }}
              
              className={`${styles["value-circle"]}  ${styles["font-w-4"]} ${styles["gradiant-button"]}`}
            >

              0{index + 1}
            </div>
            <Spacer height={".4rem"}/>
            <b>
            {f?.title}</b>
            <br />
            {/* <img style={{ width: "27px" }} src={tick} alt="" /> */}
            {f?.name}
          </div>
        ))}
      </div>
      <div
        data-aos="fade-left"
        className={`${styles[isMobile ? "w-90" : "w-60"]} ${styles["m-auto"]}`}
      >
        <div className={`${styles["head-lg"]} ${styles["center"]}`}>
          What HealthMates Help Achieve
        </div>

        <div>
          {Achieve?.map((a) => (
            <>
              <div className={`${styles[isMobile?"m-t-3":"m-t-4"]} ${styles[isMobile?"head-xsm":"head-sm"]} ${styles["line-h-2"]}`}>
                <b>{a.title}</b> {a.description}
              </div>
            </>
          ))}
        </div>
      </div>
      <div data-aos="fade-right">
        <FlexContainer isJoin={true} />
      </div>

      <Spacer height={"3rem"} />
    </div>
  );
};
export default About;
