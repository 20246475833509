import React, { useState,useRef } from "react";
import styles from "./PartnerComponent.module.css";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import Header from "../Founder/Header";
import img from "../../assets/Dev2/Mask group.png";
import google from "../../assets/Dev2/image 2773 (2).png";
import iit from "../../assets/Dev2/iitb1 2.png";
import iit2 from "../../assets/Dev2/image 2774.png";
import nascomm from "../../assets/Dev2/nascomm 1.png";
import developer from "../../assets/Dev2/Vector (7).png";
import srit from "../../assets/Dev2/srit-india-logo (1) 1.png";
import healthcare from "../../assets/Dev2/Vector (12).png";
import govt from "../../assets/Dev2/Group (2).png";
import ehr from "../../assets/Dev2/Group.png";
import insurance from "../../assets/Dev2/Vector (11).png";
import healthdata from "../../assets/Dev2/Group 2030.png";
import money from "../../assets/Home Page/icons/money.png";
import star from "../../assets/Home Page/icons/star.png";
import expand from "../../assets/Home Page/icons/expand.png";

import FlexContainer from "../home/FlexContainer";
import { useNavigate } from "react-router-dom";
import FeaturesWithCard from "../common/FeaturesWithCard";
import Spacer from "../common/Spacer";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useMediaQuery } from "react-responsive";
import { mobileDimension, tabletDimension } from "../home/CardSelector";

const PartnerComponent = () => {
  const navigate = useNavigate();
  const isMobile=useMediaQuery(mobileDimension)
  const isTablet=useMediaQuery(tabletDimension)
  const containerRef = useRef(null);
  const [{ x }, api] = useSpring(() => ({ x: 0 }));

  const bind = useDrag(({ offset: [dx] }) => {    
     if (!containerRef.current) return;     
     const maxScroll = containerRef.current.scrollWidth - containerRef.current.clientWidth;     
     // Clamp the scroll position within valid range
     const clampedX = Math.max(-maxScroll, Math.min(0, dx));     
     api.start({ x: clampedX, immediate: true }); });
  const [activeTab, setActiveTab] = useState("Innovation Partners");
  const partners = {
    "Innovation Partners": [
      {
        image: google,
        title: "Google India",
        description:
          "Providing AI expertise and infrastructure for scalable innovation.",
      },
      {
        image: iit2,
        title: " IIT Bombay & GatiShakti University",
        description:
          "Leading research collaborations in AI-driven healthcare models.",
        image2: iit,
      },
      {
        image: nascomm,
        title: "NASSCOM DeepTech",
        description: "Supporting deep-tech AI advancements in healthcare.",
      },
      {
        image: developer,
        title: "Developers & Innovators",
        description:
          "Building custom AI components, integration connectors, and fine-tuned AI ",
      },
      {
        image: false,
        title: "Be our Innovation Partner",
        description: "",
        button: true,
      },
    ],
    "GTM (Go-To-Market) Partners": [
      {
        image: google,
        title: "Google India",
        description:
          "Expanding AI-driven healthcare solutions through strategic outreach and deployment.",
      },
      {
        image: srit,
        title: "SRIT",
        description:
          "Helping deploy HealthMates.AI in hospitals where SRIT systems are already in place",
        marginLeft: "-1rem",
      },
      {
        image: healthcare,
        title: "Healthcare IT Firms",
        description: "Accelerating AI adoption through strategic partnerships.",
      },
      {
        image: govt,
        title: "Government & Policy Organizations",
        description:
          "Aligning AI-powered healthcare with national health initiatives.",
      },
    ],
    "Integration Partners": [
      {
        image: ehr,
        title: "EHR & HIMS Providers",
        description:
          "Ensuring effortless AI integration within hospital systems.",
      },
      {
        image: insurance,
        title: "Insurance & Billing Platforms",
        description:
          "Helping deploy HealthMates.AI in hospitals where SRIT systems are already in place",
      },
      {
        image: healthdata,
        title: " Healthcare Data Platforms",
        description: "Streamlining data exchange and AI-powered insights.",
      },
    ],
  };

  const featuersData = {
    name: "Vision",
    title: "Why Partner with HealthMates.AI?",
    desc: "",
    features: [
      {
        title: "Drive Innovation in AI-powered Healthcare",
        img: star,
        subTitle:
          "Be at the forefront of AI transformation in healthcare, co-developing next-gen AI solutions with industry leaders.",
      },
      {
        title: "Expand Your Market Reach ",
        img: expand,
        subTitle:
          "Gain access to a fast-growing network of hospitals and healthcare enterprises adopting AI-driven efficiencies.",
      },
      {
        title: "Monetize AI & Healthcare Solutions",
        img: money,
        subTitle:
          "Leverage our HealthMates Store to offer, deploy, and scale AI-powered healthcare solutions globally.",
      },
    ],
  };
  const contactus = () => {
    navigate("/subscribe");
  };
  return (
    <div className={styles.container}>
      {/* Header Section */}
      {/* <div className={styles.headerSection}> */}
      {/* <h1 className={styles.title}>Join Us in Revolutionizing Healthcare with AI</h1>
        <p className={styles.subtitle}>At HealthMates.AI, we believe partnerships drive innovation...</p> */}
      {/* <Header
        
        /> */}
      <div data-aos="fade-down">
        <Header
          header={"Join Us in Revolutionizing Healthcare with AI"}
          bigMessage={
            "At HealthMates.AI, we believe that partnerships drive innovation. Together, with visionary organizations, we are transforming healthcare by integrating AI-powered teammates into hospitals and clinics worldwide. Whether you are a technology provider, healthcare system, innovation hub, developer, or a hospital looking to co-create AI teammates, we invite you to be part of this game-changing AI revolution."
          }
        />
      </div>
      {/* </div> */}

      {/* Healthcare Section */}
      <h2 className={styles.sectionTitle}>
        Solve your unique problem Create your own HealthMates
      </h2>
      <div className={styles.healthcareSection}>
        <div className={styles.healthcareContent} data-aos="fade-left">
          <p className={styles.sectionText}>
            <b className={styles.health}>
              Health<span className={styles.care}>care AI Team</span>Mates
            </b>
          </p>
          <p className={styles.sectionText}>
            We invite hospitals, healthcare systems, and medical experts to work
            with us in co-creating new AI-powered HealthMates. Whether it’s a
            specialized AI concierge, automated patient triage, or AI-driven
            administrative assistant, we can develop a custom AI teammate that
            fits your needs
          </p>
        </div>
        <div className={styles.healthcareImage} data-aos="fade-right">
          <img
            src={img}
            alt="Healthcare AI"
            className={styles.responsiveImage}
          />
          <div className={styles.overlay}>
            <div className={styles.contant}>
              <h2 className={styles.firstLay}>Co-Own the Future</h2>
              <p className={styles.imageText}>
                Be recognized as a pioneer by co-developing new HealthMates that
                can be monetized and deployed worldwide.
              </p>
              <button className={styles.overlay_button} onClick={contactus}>
                Create HealthMates
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Partners Section */}
      <div className={styles.partnersSection}>
        <h2 className={styles.sectionTitle}>Our Partner Ecosystem</h2>
        <p className={styles.sectionText2}>
          They collaborate with us on cutting-edge AI models, healthcare
          insights, and specialized integrations to ensure AI teammates are
          accurate, efficient, and industry-ready.
        </p>
        <div className={styles.tabs}>
          {Object.keys(partners).map((tab) => (
            <button
              key={tab}
              className={`${styles.tabButton} ${
                activeTab === tab ? styles.activeTab : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <Spacer height={"2rem"}/>
        <div style={{ width: "100%", overflow: "hidden" }}>
          <animated.div
            {...bind()}
            ref={containerRef}
            className={styles.cardsContainer}
            style={{ x}}
          >
            {partners[activeTab].map((partner, index) => (
              <div
                key={index}
                className={styles.card}
                data-aos="fade-right"
                id={partner?.button ? styles.idcard : ""}
              >
                {partner?.image ? (
                  <img
                    src={partner.image}
                    alt={partner.title}
                    className={styles.cardImage}
                    style={{
                      marginLeft: partner?.marginLeft
                        ? partner.marginLeft
                        : "0rem",
                    }}
                  />
                ) : (
                  <></>
                )}
                {partner?.image2 ? (
                  <img
                    src={partner.image2}
                    alt={partner.title}
                    className={styles.cardImage}
                    style={{ marginLeft: ".8rem" }}
                  />
                ) : (
                  <></>
                )}
                <div className={styles.cardContent}>
                  <h3 className={styles.cardTitle}>{partner.title}</h3>
                  {partner?.description ? (
                    <p className={styles.cardText}>{partner.description}</p>
                  ) : (
                    <></>
                  )}
                </div>
                {partner?.button ? (
                  <button className={styles.join} onClick={contactus}>
                    Join Us
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </animated.div>
        </div>
        {/* <div className={styles.cardsContainer}>
          {partners[activeTab].map((partner, index) => (
            <div
              key={index}
              className={styles.card}
              data-aos="fade-right"
              id={partner?.button ? styles.idcard : ""}
            >
              {partner?.image ? (
                <img
                  src={partner.image}
                  alt={partner.title}
                  className={styles.cardImage}
                  style={{
                    marginLeft: partner?.marginLeft
                      ? partner.marginLeft
                      : "0rem",
                  }}
                />
              ) : (
                <></>
              )}
              {partner?.image2 ? (
                <img
                  src={partner.image2}
                  alt={partner.title}
                  className={styles.cardImage}
                  style={{ marginLeft: ".8rem" }}
                />
              ) : (
                <></>
              )}
              <div className={styles.cardContent}>
                <h3 className={styles.cardTitle}>{partner.title}</h3>
                {partner?.description ? (
                  <p className={styles.cardText}>{partner.description}</p>
                ) : (
                  <></>
                )}
              </div>
              {partner?.button ? (
                <button className={styles.join} onClick={contactus}>
                  Join Us
                </button>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div> */}
      </div>
      <div data-aos="fade-up">
        <Spacer height={"3rem"} />

        <FeaturesWithCard
          border={true}
          grid={isMobile?1:isTablet?2:3}
          min_height="222px"
          isCenter={true}
          data={featuersData}
        />
      </div>
      <Spacer height={"3rem"} />
      <div data-aos="fade-up">
        <FlexContainer />
      </div>
    </div>
  );
};

export default PartnerComponent;
