import React from "react";
import styles from "../../styles/main.module.css";
import success from "../../assets/success.png"
import { useNavigate } from "react-router-dom";
const Success = ({text=""}) => {
    const navigate=useNavigate()
    return (
        <div style={{  }} className={`${styles?.["flex-column"]} `}>
            <div style={{ width:"234px" }} >
<img style={{ width:"100%"}} src={success} alt="" />
            </div>
            
            <h1>Success! 🎉</h1>
            <h3 className={`${styles?.["center"]} ${styles?.["w-50"]}  `} >{text}</h3>
            <div>
                <button style={{ border:"1px solid white" }} onClick={()=>navigate("/")}  className={`${styles?.["outline-button"]} `} >Back Home</button>
            </div>
        </div>
    );
};
export default Success;