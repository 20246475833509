import React, { useState } from "react";
import MainCover from "../components/home/MainCover";
import styles from "../styles/main.module.css";
import Spacer from "../components/common/Spacer";
import { add_contact_us } from "../api/Form/FormApi";
import Success from "../components/common/Success";
import Error from "../components/common/Error";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const Contact = () => {
  const state = useSelector((state) => state);
  const isMobie = useMediaQuery({ maxWidth: "500px" });
  const [botName, setBotName] = useState(state?.botNameReducer?.data || "");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [contactInfo, setContactInfo] = useState({
    flag: 1,
    first_name: "",
    last_name: "",
    email: "",
    demo:0,
    subject: "-",
    message: "",
    country: "India",
    company_name: "",
    device: 0,
    bot_name: botName || "100",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prev) => ({ ...prev, [name]: value }));
  };
  console.log(state);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await add_contact_us(contactInfo);
    if (res.message == "Contact message saved and email sent!") {
      setIsSuccess(true);
      setContactInfo({
        flag: 0,
        first_name: "",
        last_name: "",
        email: "",
        subject: "",
        message: "",
        country: "",
        company_name: "",
        device: 0,
        bot_name: "",
      });
    } else {
      setIsError(true);
    }
    console.log(res);
    console.log(contactInfo);
  };
  return (
    <div
      data-aos="fade-down"
      style={isMobie ? { gap: "1rem" } : {gap:"2rem"}}
      className={`${styles?.["flex-column-str"]}  `}
    >
      {}
      {/* <MainCover /> */}
      <Spacer height={".1rem"} />
      <div
        className={`${styles[isMobie ? "w-90" : "w-35"]} ${styles["m-auto"]} ${
          styles.center
        }`}
      >
        <div className={styles["head-lg"]}>Contact Us</div>
        <Spacer height={"1rem"} />
        <div className={styles["head-sm"]}>
          The global scarcity of healthcare workers is, in our opinion, the
          biggest threat to patient safety.
        </div>
      </div>
      {/* <Spacer height={".5rem"} /> */}
      {isSuccess ? (
        <>
          <Success text="Your information has been recorded successfully" />
        </>
      ) : isError ? (
        <Error />
      ) : (
        <div
          style={{ gap: "2rem" }}
          className={`${
            window.innerWidth > 700 ? styles?.["w-60"] : styles["w-80"]
          } ${styles?.["form-container"]}  ${styles?.["contact-form"]} ${
            styles?.["flex-column-str"]
          } ${styles?.["m-auto"]}  `}
        >
          <form onSubmit={handleSubmit} action="">
            <div
              style={{ display: isMobie ? "block" : "flex", gap: "2.3rem" }}
              className={`${styles?.["flex-gap"]}  `}
            >
              <div
                className={`${isMobie ? styles?.["w-100"] : styles?.["w-50"]} ${
                  styles?.[""]
                }  `}
              >
                <label>Name <span className={styles.mandat}>*</span></label>
                <input
                  required
                  name="first_name"
                  value={contactInfo.first_name}
                  onChange={handleInputChange}
                  placeholder="First Name"
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="text"
                />
              </div>
              <div
                className={`${isMobie ? styles?.["w-100"] : styles?.["w-50"]} ${
                  styles?.[""]
                }  `}
              >
                <div style={{ minHeight: "1.2rem" }}></div>

                <input
                  required
                  name="last_name"
                  value={contactInfo.last_name}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="text"
                />
              </div>
            </div>
            <Spacer height="2rem" />
            <div
              style={{ width: "100%" }}
              className={`${styles?.["categories-container2"]} ${styles?.[""]}  `}
            >
              <div>
                <label>Work Email <span className={styles.mandat}>*</span></label>
                <input
                  required
                  name="email"
                  onChange={handleInputChange}
                  value={contactInfo.email}
                  placeholder="Type...."
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="email"
                />
              </div>
              {/* <div>
                <label>
                  Subject{" "}
                  <span style={{ fontSize: ".8rem" }}>
                    (Max 200 characters)
                  </span>
                </label>
                <input
                  name="subject"
                  required
                  onChange={handleInputChange}
                  value={contactInfo.subject}
                  maxLength="200"
                  placeholder="Type....."
                  // placeholder="First Name"
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="text"
                />
              </div> */}

              <div>
                <label>
                  Organisation Name <span className={styles.mandat}>*</span>{" "}
                  {/* <span style={{ fontSize: ".8rem" }}>(Max 200 characters)</span> */}
                </label>
                <input
                  name="company_name"
                  required
                  onChange={handleInputChange}
                  value={contactInfo.company_name}
                  // maxLength="200"
                  placeholder="Type....."
                  // placeholder="First Name"
                  className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                  type="text"
                />
              </div>
              <div>
                <label>
                  Country Name <span className={styles.mandat}>*</span>{" "}
                  {/* <span style={{ fontSize: ".8rem" }}>(Max 200 characters)</span> */}
                </label>
                <select
                  style={{ width:isMobie?"98%": "104%" }}
                  className={`${styles?.["inpt-box"]}  `}
                  name="country"
                  options={options}
                  value={contactInfo.country}
                  onChange={handleInputChange}
                >
                  {options?.map((op) => (
                    <option value={op?.label} label={op?.label}>
                      {op?.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Spacer height={"1rem"} />

            <div>
              <label>
                Message {" "}
                <span style={{ fontSize: ".8rem" }}>(Max 1000 characters)</span>{" "}<span className={styles.mandat}>*</span>
              </label>
              <textarea
                required
                name="message"
                onChange={handleInputChange}
                rows="3"
                style={{
                  resize: "vertical",
                  overflow: "auto",
                  height: "6rem",
                  width: isMobie?"95%": "100%" ,
                  paddingTop: ".5rem",
                }}
                maxLength="1000"
                value={contactInfo.message}
                placeholder="Type...."
                className={`${styles?.["inpt-box"]} ${styles?.[""]}  `}
                type="text"
              />
            </div>
            <Spacer height={"1rem"} />
            <div className={styles.center}>
              <button
                type="submit"
                // onClick={handleSubmit}
                className={`${styles?.["gradiant-button"]} ${styles?.["form-submit-button"]}`}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
      <Spacer height={"1rem"} />
    </div>
  );
};
export default Contact;
