import React from "react";
import styles from "./Founders.module.css";
const Header = ({ header = "", smallMessage = "", bigMessage = "" }) => {
  return (
    <div className={styles.centre}>
      {" "}
      <h1 className={styles.header}>{header}</h1>
      <p className={styles.smallMessage}>{smallMessage}</p>
      <p className={styles.bigMessage}>{bigMessage}</p>
    </div>
  );
};

export default Header;
