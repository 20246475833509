import React, { useEffect, useState } from "react";

import styles from "../styles/main.module.css";
import AIFeatures from "../components/home/AIFeatures";
import listening from "../assets/Home Page/icons/listening.png";
import tree from "../assets/Home Page/icons/tree.png";
import hospital from "../assets/Home Page/icons/hospital.png";
import money from "../assets/Home Page/icons/money.png";
import users from "../assets/Home Page/icons/users.png";
import doctor from "../assets/Home Page/doctor.png";
import clock from "../assets/Home Page/icons/clock.png";
import growth from "../assets/Home Page/icons/garden_growth-chart-fill-16.png"
import tick from "../assets/Home Page/icons/tick-circle.png";
import scale from "../assets/Home Page/icons/scale.png"



import secure from "../assets/Home Page/icons/secure.png";
import operation from "../assets/Home Page/operation.png";

import nurse from "../assets/Home Page/nurse.png";
import consult from "../assets/Home Page/consult.png";

import Spacer from "../components/common/Spacer";
import FlexContainer from "../components/home/FlexContainer";
import ContentSlider from "../components/WhyHealthmates/ContentSlider";
import KeyDifferences from "../components/home/KeyDifferences";
import FeaturesWithCard from "../components/common/FeaturesWithCard";
import { useMediaQuery } from "react-responsive";
import Header from "../components/Founder/Header";
import { tabletDimension } from "../components/home/CardSelector";
// import listening from "../assets/Home Page/icons/listening.png"

const WhyHealthmates = () => {
    const isMobile = useMediaQuery({ maxWidth: "500px" });
    const isTab =useMediaQuery(tabletDimension)
  const sliderData = [
    {
      tagline: "Overcoming Staff Shortages",
      problem:
        "Global healthcare is facing an acute shortage of professionals. The WHO predicts a shortfall of 10 million healthcare workers by 2030",
      solution:
        "AI-powered teammates fill operational gaps by handling patient queries, triage, follow-ups, medical documentation, and more—freeing human professionals to focus on care",
      solutions: [],
      image:doctor
    },
    {
      tagline: "Enhancing Patient Experience",
      problem:
        "Long wait times, delayed responses, and fragmented patient journeys lead to frustration and poor healthcare outcomes",
      solution:
        "",
      solutions: [
        "● AI-powered scheduling ensures appointments are optimized, reducing wait times.",
        "● Virtual concierges answer patient queries instantly.",
        "● Personalized follow-ups improve post-hospital care and reduce readmissions",
      ],
      image:nurse

    },
    {
      tagline: "Reducing Administrative Burdens",
      problem:
        "Doctors and nurses spend up to 40% of their time on documentation instead of patient care.",
      solution:
        "",
      solutions: [
        "● Siya, the AI Medical Scribe – Automates clinical documentation, allowing doctors to focus on patients.",
        "● Medusa, the AI Medical Coder – Ensures accurate billing & faster claim approvals.",
        "● Andi, the AI Nursing Assistant – Manages handover documentation and shift reports.",
      ],
      image:operation

    },
    {
      tagline: "Optimizing Revenue & Insurance Processing",
      problem:
        "Hospitals lose millions annually due to insurance claim denials, revenue leakage, and inefficiencies in billing.",
      solution:
        "",
      solutions: [
        "● Salvator, the AI Insurance Specialist – Speeds up claims processing & validation.",
        "● Ana, the AI Financial Analyst – Provides actionable insights to boost revenue & cut costs.",
      ],
      image:consult

    },
  ];
  const features = [
    {
      img: users,
      title: "AI Teammates, Not Just AI Tools",
      description: "",
    },
    {
      img: hospital,
      title: "Deep Integration with Hospital IT",
      description: "",
    },
    {
      img: listening,
      title: "Outcome-Driven, Not Just Task-Based",
      description: "",
    },
    {
      img: tree,
      title: "Multimodal AI",
      description: "",
    },
    {
      img: money,
      title: "Scalable & Cost-Effective",
      description: "",
    },
    {
      img: secure,
      title: "Security & Compliance First ",
      description: "",
    },
  ];
  const coverData = {
    heading: "Why HealthMates?",
    subHeading: "The Future of Healthcare: AI That Works Like Humans",
    description:
      "In a world where healthcare systems are overburdened, staff shortages are on the rise, and administrative inefficiencies impact both patient outcomes and hospital revenue, HealthMates.AI is the game-changer. We don’t just automate processes—we redefine how healthcare works, empowering organizations with intelligent, autonomous AI teammates that seamlessly integrate into hospital operations.",
  };
  const keyDifferenceData=
  {
    title:"Real Impact: Why Hospitals Love HealthMates.AI",
    desc:"Hospitals that deploy HealthMates.AI see a return on investment within just 4-6 months!",
    features:[
      {
        title:"Staffing Costs Reduced by 25-30%",
        img:money,
        subTitle:" AI teammates augment and support human workers",
      },
      {
        title:"Administrative Tasks Completed 50% Faster",
        img:tick,
        subTitle:"AI-driven automation streamlines workflows.",
      },
      {
        title:"Revenue Growth of 10-15%",
        img:growth,
        subTitle:"Optimized appointments, claims, and billing",
      },
      {
        title:"Wait Times Cut by 30%",
        img:clock,
        subTitle:"Smarter scheduling & AI-based patient interactions",
      },
    ],
  }
 const featuresData= {
    title:"The Next Evolution: Build Your Own HealthMate",
    desc:"Soon, we will introduce a revolutionary AI configurator, allowing healthcare providers to:",
    features:[
      {
        title:"Design & Deploy Custom AI Teammates",
        img:scale,
        subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
      {
        title:"Commercialize AI Teammates via the HealthMates Store",
        img:users,
        subTitle:"Monetize custom-built AI solutions and make them available for adoption worldwide.",
      },
      {
        title:" Instantly Scale & Adapt",
        img:growth,
        subTitle:" No coding required! Deploy AI teammates in days, not months.",
      },
    ],
  }
  return (
    <div
      style={{ gap: "5rem" }}
      className={`${styles["flex-column-str"]} ${styles[""]}`}
    >
      {isMobile?<></>:<Spacer height={"1rem"}/>}
      
        <div data-aos="fade-down">
          {isMobile?<Spacer height={"1rem"}/>:<></>}
      <Header 
      header={"Why HealthMates?"}
      smallMessage={"The Future of Healthcare: AI That Works Like Humans"}
      bigMessage={"In a world where healthcare systems are overburdened, staff shortages are on the rise, and administrative inefficiencies impact both patient outcomes and hospital revenue, HealthMates.AI is the game-changer. We don’t just automate processes—we redefine how healthcare works, empowering organizations with intelligent, autonomous AI teammates that seamlessly integrate into hospital operations."}
      />
      </div>
      <div className={`${styles[isMobile?"w-95":"w-70"]} ${styles["m-auto"]}`}>
        <AIFeatures
          isBot={false}
          data={{
            first_title: "What Sets HealthMates Apart?",
            second_title: "",
            description: "",
            features: features,
          }}
          isCenter={true}
        />
      </div>
      <div>
        <ContentSlider data={sliderData} />
      </div>
      <div>
    <KeyDifferences data={keyDifferenceData} />
      </div>
      <FeaturesWithCard min_height="230px" grid={isMobile?1:isTab?2:3} data={featuresData}/>
      <div>
        <FlexContainer isJoin={true} />
      </div>

      <Spacer height={"2rem"} />
    </div>
  );
};
export default WhyHealthmates;
