import React, { useEffect, useState } from "react";

import styles from "../styles/main.module.css";
import olympic from "../assets/Research/image 2674.jpg";
import darwin from "../assets/Research/image 2675.jpg";
// import conferrence from "../assets/Research/image 2676.jpg";
import pushup from "../assets/Research/pushup.png";
import conferrence from "../assets/Research/conferrence.png";
import cycle from "../assets/Research/cycle.png";
import brain from "../assets/Research/brain.png";
import headline from "../assets/Research/headline.png";
import conferrenceGoogle from "../assets/Research/conferrence-google.png";
import Financial from "../assets/Research/financialExpress.png";

import FinancialOutlook from "../assets/Research/financeOutlook.png";

import insights from "../assets/Research/Insights.png";

import newsOutlook from "../assets/Research/newOutlook.png";
import mirrorNow from "../assets/Research/mirrorNow.png";

import mirrorNow1 from "../assets/Research/mirrorNow1.png";
import techgraph from "../assets/Research/techGraph.png";

import MainCover from "../components/about/MainCover";
import NewsFeed from "../components/common/NewsFeed";
import JoinTeam from "../components/common/JoinTeam";

import Spacer from "../components/common/Spacer";
import { useMediaQuery } from "react-responsive";
import { get_blog } from "../api/NewsFeed/blog";

const Research = () => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  const [newsData, setNewsData] = useState([]);
  const news = [
    {
      title: "CNBC-TV18",
      informerLink: "@IANS wire service",
      Date: "November 20, 2024",
      summary:
        "Shitiz Ruhail, Co-Founder of Digital Darwin, on Navigating Digital Darwinism with Innovation and Agility",
      img: pushup,
      link: "https://www.ians.in/vmpl/shitiz-ruhail-co-founder-of-digital-darwin-on-navigating-digital-darwinism-with-innovation-and-agility",
    },
    {
      title:
        "Digital Darwin: Recolonizing Sports Tech with computer vision... ",
      informerLink: "@Mirror Now",
      Date: "November 16, 2024",
      summary:
        "Generational Clash At Work: Gen Z Redefines 9-to-5. Will 'Traditionals' Catch Up?",
      img: headline,
      link: "https://youtu.be/kBID09bzPSM?si=LWmo18dPuqlC4NBY",
    },
    {
      title: "Digital Darwin Partners with Google to Enhance AI",
      informerLink: "@Firstpost",
      Date: "November 7, 2024",
      summary:
        "The $1 tn conversation: How AI assistants will revolutionise our lives",
      img: brain,
      link: "https://www.firstpost.com/connect/the-1-tn-conversation-how-ai-assistants-will-revolutionise-our-lives-13832983.html",
    },
    {
      title: "CNBC-TV18",
      informerLink: "@CXOtoday",
      Date: "November 7, 2024",
      summary:
        "Digital Darwin: Revolutionizing Sports Tech with Computer Vision, Generative AI, and Integrated Analytics",
      img: cycle,
      link: "https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare",
    },
    {
      title:
        "Digital Darwin: Recolonizing Sports Tech with computer vision... ",
      informerLink: "@IANS wire service",
      Date: "October 7, 2024",
      summary:
        "Digital Darwin Partners with Google to Enhance AI Innovation in Sports and Healthcare",
      img: conferrence,
      link: "https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare",
    },
    {
      title: "Digital Darwin Partners with Google to Enhance AI",
      informerLink: "@CNBC-TV18",
      Date: "July 26, 2024",
      summary:
        "All eyes on #ParisOlympics2024—but, back in India, @iitmadras has its sights set on #Olympics 2036",
      img: conferrenceGoogle,
      link: "https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08",
    },
  ];
  const temp = [
    {
      title: "@MIRROR NOW",
      informer_link: "https://www.youtube.com/watch?v=gT2SSwZgptQ",
      date: "February 10, 2025",
      summary:
        "PM Modi Co-Chairs AI Action Summit, Musk Warns Threat, Can China's DeepSeek Keep Up?",
      image: mirrorNow1,
    },
    {
      title: "@tech graph",
      informer_link:
        "https://techgraph.co/budget-2025/india-ai-budget-allocation-stirs-mixed-reactions/",
      date: "February 03, 2025",
      summary:
        "Union Budget 2025: India’s AI Budget Allocation Stirs Mixed Reactions",
      image: techgraph,
    },
    {
      title: "@MIRROR NOW",
      informer_link: "https://youtu.be/W0M4hMMeSuI?si=cdgg3A4XoeCCJU0q",
      date: "February 01, 2025",
      summary:
        "India Unveils Mega 'Desi AI' Plan; Ready To 'BYTE' Back at Giants? | Beyond The Headline",
      image: mirrorNow,
    },
    {
      title: "@News Outlook",
      informer_link:
        "https://news-outlook.com/transforming-healthcare-with-ai-shitiz-ruhail-on-how-healthmates-ai-is-redefining-patient-engagement-and-operational-efficiency/",
      date: "January 29, 2025",
      summary:
        "Transforming Healthcare with AI: Shitiz Ruhail on How HealthMates.AI is Redefining Patient Engagement...",
      image: newsOutlook,
    },
    {
      title: "@Finance Outlook",
      informer_link:
        "https://news-outlook.com/transforming-healthcare-with-ai-shitiz-ruhail-on-how-healthmates-ai-is-redefining-patient-engagement-and-operational-efficiency/",
      date: "January 25, 2025",
      summary:
        "Union Budget Expectations 2025-26: Policies and Reforms to Position India as the Global Healthtech Hub",
      image: FinancialOutlook,
    },
    {
      title: "@Insights",
      informer_link:
        "https://www.financeoutlookindia.com/opinion/union-budget-expectations-202526-policies-and-reforms-to-position-india-as-the-global-healthtech-hub-nwid-4126.html#google_vignette",
      date: "January 02, 2025",
      summary:
        "How AI is transforming India’s healthcare system with tools, and ethical challenges",
      image: insights,
    },
    {
      title: "@Financial Express",
      informer_link:
        "https://etedge-insights.com/industry/healthcare/how-ai-is-transforming-indias-healthcare-system-with-tools-and-ethical-challenges/",
      date: "December 28, 2024",
      summary:
        "How AI, VR, and the Metaverse are revolutionising the fitness industry",
      image: Financial,
    },
  ];
  const fetchData = async () => {
    const res = await get_blog();
    console.log(res);
    setNewsData(res);
  };

  useEffect(() => {
    // fetchData();
  }, []);
  return (
    <div
      data-aos="fade-down"
      className={`${styles?.["flex-column-str"]} ${styles?.[""]}  `}
    >
      {/* <MainCover
        title="Research & Safety Blog"
        subTitle="New LLM and Clinical Updates"
      /> */}
      {isMobile?<></>:<Spacer height={".5rem"} />}
      
      <div
        className={`${styles[isMobile ? "w-90" : "w-35"]} ${styles["m-auto"]} ${
          styles.center
        }`}
      >
        <div className={styles["head-lg"]}>Research & Blogs</div>
        <Spacer height={"1rem"} />
        <div className={styles["head-sm"]}>
          The global scarcity of healthcare workers is, in our opinion, the
          biggest threat to patient safety.
        </div>
      </div>
      {isMobile?<></>:<Spacer height={".5rem"} />}


      <div
        style={{ gap: "3rem" }}
        className={` ${isMobile ? "" : styles?.["m-b-5"]} ${
          styles?.["flex-column"]
        }  `}
      >
        {temp?.map((news, i) => (
          <div data-aos={i % 2 == 0 ? "fade-right" : "fade-left"}>
            <NewsFeed
              isLeft={false}
              isNews={true}
              title={news.title}
              img={`${news.image}`}
              summary={news.summary}
              link={news.informer_link}
              date={news.date}
              // link={news.link}
            />
          </div>
        ))}
      </div>
      {/* <JoinTeam/> */}
      <Spacer height={"2rem"} />
    </div>
  );
};
export default Research;
