import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../../styles/main.module.css";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import "swiper/css/effect-cube";
import "swiper/css/effect-cards";



// import './styles.css';

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard,EffectCube,EffectFade, EffectCards } from 'swiper/modules';

import Spacer from "../common/Spacer";

import ritik from "../../assets/Home Page/ritik.png";
import botData from "../../data/db.json";
import { useMediaQuery } from "react-responsive";
import SliderContent from "./SliderContent";

export const mobileDimension = { maxWidth: "499px" };
export const tabletDimension = { maxWidth: "900px", minWidth: "500px" };
export default function ContentSlider({data}) {
  const isMobile = useMediaQuery(mobileDimension);
  const isDesktop = useMediaQuery(tabletDimension);
  const [filteredBots, setFilteredBots] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleIndexChange = (i) => {
    setCurrentIndex(i);
  };
  useEffect(() => {
    setFilteredBots(botData?.filter((bot) => bot?.category == currentIndex));
  }, [currentIndex]);
  return (
    <>
      <div >
        <div style={{ padding: "2rem 2rem 0rem 2rem" }}>
          <div
            className={`${styles["head-lg"]} ${
              styles[ "center" ]
            } ${styles[isMobile ? "w-100" : "w-70"]} ${styles["m-auto"]}`}
          >
            Solving Critical Challenges
          </div>
          <Spacer height={"1rem"} />
        </div>
        <Spacer height={"1rem"} />

        <div className={styles.sliderContianer} >
          <Swiper
            cssMode={true}
            navigation={true}
            effect="cards"
            // fadeEffect={{crossFade:true}}
            pagination={true}
            speed={1000}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard,EffectCube,EffectFade]}
            className="mySwiper"
          >
            {data?.map((slide, i) => (
              <SwiperSlide>
                <SliderContent data={slide}/>
                {/* <BotCard data={data} /> */}
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <Spacer height={"1rem"} /> */}
          {/* <div style={{ gap: "1.1rem" }} className={styles["flex-center"]}>
            <div
              style={{ cursor: "pointer" }}
              className="custom-prev absolute left-0 bottom-0  z-10 cursor-pointer text-3xl"
            >
              <IoIosArrowDropleftCircle size={40} />
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="custom-next absolute right-0 z-10 cursor-pointer text-3xl"
            >
              <IoIosArrowDroprightCircle size={40} />
            </div>
          </div> */}
          {/* <Spacer height={"1rem"} /> */}
        </div>
      </div>
    </>
  );
}
