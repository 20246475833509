import Header from "../Founder/Header";
import styles from "./SafeAI.module.css";
import img from "../../assets/Dev2/Mask group_new.png"
import img2 from "../../assets/Dev2/Mask group_cip.png"
import secure from "../../assets/Home Page/icons/secure.png"
import clock from "../../assets/Home Page/icons/clock.png"
import stamp from "../../assets/Home Page/icons/stamp.png"


import graduation from "../../assets/Home Page/icons/graduation.png"
import star from "../../assets/Home Page/icons/star.png"

import FlexContainer from "../home/FlexContainer";
import FeaturesWithCard from "../common/FeaturesWithCard";
import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
import { mobileDimension, tabletDimension } from "../home/CardSelector";

const SafeAI = () => {
  const isMobile=useMediaQuery(mobileDimension)
  const isTablet=useMediaQuery(tabletDimension)
  const arr=[{title:"Automated Threat Detection & Alerts ",details:"-AI-driven security monitoring for real-time anomaly detection."},
    {title:"Rapid Incident Response Team",details:"– Dedicated experts for immediate action in case of security breaches."},
    {title:"Transparent Reporting ",details:"– Hospitals and partners receive regular security updates and audit logs."},
    {title:"Regulatory Compliance Reporting ",details:"– Adhering to all mandated security reporting guidelines."}]

    const featuresData= {
      name: "Vision",
      title: "Continuous Auditing & Third-Party Assessments",
      desc: "",
      features: [
        {
          title: "Quarterly Internal Compliance Audits",
          img: stamp,
          subTitle: " Ensuring adherence to regulatory frameworks.",
        },
        {
          title: "Third-Party Penetration Testing",
          img: secure,
          subTitle: "Regular security testing by external firms to identify and mitigate risks.",
        },
        {
          title: "AI Performance Benchmarking",
          img: star,
          subTitle: "Periodic AI safety and effectiveness assessments.",
        },
        // {
        //   title: "Automated compliance verification",
        //   img: "bitcoin",
        //   subTitle: "",
        // },
      ],
    }
    const featuresData2= {
      name: "Vision",
      title: "Patient Safety & Operational Reliability",
      desc: "HealthMates.AI ensures that patient safety is at the core of every AI-driven interaction by maintaining rigorous testing, validation, and clinical accuracy.",
      features: [
        {
          title: "Fail safe Mechanism",
          img: secure,
          subTitle: " ",
        },
        {
          title: " 24/7 Monitoring & Real-Time Auditing",
          img: clock,
          subTitle: "",
        },
        {
          title: "Clinical AI Training Data",
          img: star,
          subTitle: "",
        },
        {
          title: "Adaptive Learning & Continuous Improvement",
          img: graduation,
          subTitle: "",
        },
      ],
    }
  return (
    <div className={styles.safeAIContainer}>
      {/* Top Section */}
      <div data-aos="fade-down">
      <Header 
      header={"AI You Can Trust: Keeping Healthcare Safe"}
      bigMessage={"At HealthMates.AI, we prioritize patient safety, data security, and ethical AI use in healthcare. Our AI-powered teammates are designed to assist—not replace—healthcare professionals, ensuring accuracy, compliance, and reliability in every interaction. Seamlessly integrated into hospital systems, they analyze, support, and act while adhering to global healthcare regulations."}
      /></div>
      <div className={styles.topSection}>
        {/* Left Side: Header & Big Message */}
        <div className={styles.leftContent}  data-aos="fade-right">
          <h2 className={styles.lefth2}>Protecting Patient Data with Advanced Security</h2>
          <p className={styles.left_p}>
          At HealthMates.AI, we implement cutting-edge security measures to safeguard patient data and meet the world’s strictest healthcare compliance standards.
          </p>
        </div>

        {/* Right Side: Image with Overlay */}
        <div className={styles.rightImage}  data-aos="fade-left">
          <img src={img} alt="Security Compliance" className={styles.image} />
          <div className={styles.overlayText}>
            <p className={styles.overlay_p}>SOC 2 Certified – Demonstrating best-in-class data security and governance.</p>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className={styles.bottomSection}>
         {/* Right Side: Header & Small Message */}
         <div className={styles.rightContent}  data-aos="fade-left">
          <h2 className={styles.lefth2}>AI That Operates Safely, Ethically, and Transparently</h2>
          <p className={styles.left_p}>
            At HealthMates.AI, we ensure that every AI-powered decision aligns
            with clinical best practices, safety protocols, and ethical
            standards.
          </p>
        </div>
        {/* Left Side: Four Gradient Cards */}
        <div className={styles.cardsContainer}  data-aos="fade-right">
          <div className={styles.card}>Hallucination Mitigation</div>
          <div className={styles.card}>Explainable AI (XAI)</div>
          <div className={styles.card}>Human-in-the-Loop Oversight</div>
          <div className={styles.card}>Bias Detection & Ethical AI</div>
        </div>

       
      </div>
      {isMobile?<></>: <Spacer height={"2rem"}/>}


      <FeaturesWithCard
          border={false}
          grid={isMobile ||isTablet?2:4}
          isCenter={true}
          data={featuresData2}
        />
              {isMobile?<></>: <Spacer height={"2rem"}/>}


      <div className={styles.topSection}>
        {/* Left Side: Header & Big Message */}
        <div className={styles.leftContent} data-aos="fade-right">
          <h2 className={styles.lefth2} style={{marginLeft:"1.1rem"}}>Incident Response & Escalation Protocols</h2>
          {/* <p className={styles.left_p}>
          At HealthMates.AI, we implement cutting-edge security measures to safeguard patient data and meet the world’s strictest healthcare compliance standards.
          </p> */}
          <ul>
            {arr.map((el)=>{
            return  <li className={styles.li}>
                <b>{el?.title}</b><span>{el?.details}</span>
              </li>
            })}
          </ul>
        </div>

        {/* Right Side: Image with Overlay */}
        <div className={styles.rightImage} data-aos="fade-left">
          <img src={img2} alt="Security Compliance" className={styles.image} style={{width:"85%",margin:"auto",display:"block"}} />
        </div>
      </div>
      {isMobile?<></>: <Spacer height={"2rem"}/>}
     
      <div>
      <FeaturesWithCard
          border={true}
          grid={isMobile ?1:isTablet?2:3}
          min_height="220px"
          isCenter={true}
          data={featuresData}
        />
        </div>
        {isMobile?<></>: <Spacer height={"2rem"}/>}


      <div data-aos="fade-up">
      <FlexContainer />
      </div>
    </div>
  );
};

export default SafeAI;
