import React, { useState } from "react";
import styles from "../../styles/main.module.css";
import FeatureCard from "../home/FeatureCard";
import Spacer from "./Spacer";
import { useMediaQuery } from "react-responsive";
import { mobileDimension, tabletDimension } from "../home/CardSelector";
const FeaturesWithCard = ({
  grid = 3,
  data,
  isCenter = false,
  border = false,
  fullWidth=false,
  isFlip=false,
  min_height="200px",
  backgroundColor
}) => {
  const isMobile = useMediaQuery(mobileDimension);
  const isTab=useMediaQuery(tabletDimension)
  return (
    <div
      style={{ gap: "1rem" }}
      className={`${styles[fullWidth?"w-100":"w-90"]} ${styles["flex-column"]} ${styles["m-auto"]}`}
    >
    {data?.title?<div
      className={`${styles[isMobile ? "w-95" : "w-70"]} ${
        styles["head-lg"]
      } ${styles["center"]}`}
    >
      {data?.title}
    </div>:<></>}
    {
      data?.desc?<div
      className={`${styles[isMobile ? "w-95" : "w-60"]} ${
        styles["head-sm"]
      } ${styles["font-w-4"]} ${styles["center"]}`}
    >
      {data?.desc}
    </div>:<></>
    }
    {isFlip?<> </>:<><Spacer height={"1rem"} /></>}
     
      <div
        style={{
          gap: "1rem",
          gridTemplateColumns: `repeat(${ grid},1fr)`,
          width: grid == 3 ? (isMobile ? "100%" : isTab?"95%":"80%") : "",
          margin: "auto",
        }}
        className={styles["feature-grid"]}
      >
        {data?.features?.map((feature, i) => (
          <FeatureCard
            title={feature?.title}
            img={feature?.img}
            isKeyDifference={false}
            min_height={min_height}
            border={border}
            backgroundColor={backgroundColor}
            isCenter={isCenter}
            subTitle={feature?.subTitle}
            flip_description={feature?.flip_description}
          />
        ))}
      </div>
    </div>
  );
};
export default FeaturesWithCard;
