import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import botStyles from "../Profile/ProfileData.module.css";
import styles from "../../styles/main.module.css";
import animation from "../../styles/animation.module.css";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import { IoMdCloseCircleOutline } from "react-icons/io";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import Spacer from "../common/Spacer";

import ritik from "../../assets/Home Page/ritik.png";
import BotCard from "./BotCard";
import botData from "../../data/db.json";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export const mobileDimension = { maxWidth: "499px" };
export const tabletDimension = { maxWidth: "1024px", minWidth: "500px" };
export default function CardSelector() {
  const isMobile = useMediaQuery(mobileDimension);
  const isDesktop = useMediaQuery(tabletDimension);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch=useDispatch()
  const [ModalData, setModalData] = useState({});
  const [filteredBots, setFilteredBots] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const catergories = [
    {
      title: "Patient Experience",
      // sub_title: "Increase Patient Experience by 10%",
    },
    {
      title: "Operations Efficiency",
      // sub_title: "Increase Operation Efficiency by 10%",
    },
    {
      title: "Revenue Enhancement",
      // sub_title: "Enhance Revenue by 10%"
    },
  ];
  const bots = [
    {
      name: "Hoja",
      role: "Patient Concierge",
      description:
        "AI employee Contact Centre Agent providing users answers regarding their queries about the hospital and its services.",
      replacedRole: "Medical Coder",
      gender: "",
      category: 0,
      details: ["40% more productive", "28% Cheaper"],
      image: ritik,
    },
    {
      name: "sofia",
      role: "Patient Concierge",
      description:
        "AI employee Contact Centre Agent providing users answers regarding their queries about the hospital and its services.",
      replacedRole: "Medical Coder",
      gender: "",
      category: 1,
      details: ["40% more productive", "28% Cheaper"],
      image: ritik,
    },
    {
      name: "Ana",
      role: "Patient Concierge",
      description:
        "AI employee Contact Centre Agent providing users answers regarding their queries about the hospital and its services.",
      replacedRole: "Medical Coder",
      gender: "",
      category: 1,
      details: ["40% more productive", "28% Cheaper"],
      image: ritik,
    },
    {
      name: "Tina",
      role: "Patient Concierge",
      description:
        "AI employee Contact Centre Agent providing users answers regarding their queries about the hospital and its services.",
      replacedRole: "Medical Coder",
      gender: "",
      category: 2,
      details: ["40% more productive", "28% Cheaper"],
      image: ritik,
    },
    {
      name: "Ritik",
      role: "Patient Concierge",
      description:
        "AI employee Contact Centre Agent providing users answers regarding their queries about the hospital and its services.",
      replacedRole: "Medical Coder",
      gender: "",
      category: 2,
      details: ["40% more productive", "28% Cheaper"],
      image: ritik,
    },
  ];
    useEffect(()=>{
   if(isOpen){
    document.body.style.overflow="hidden"
   }else{
    document.body.style.overflow=""
    
   }
   return()=>{
    document.body.style.overflow=""
  
   }
    },[isOpen])
  const navigate = useNavigate();
  const handleIndexChange = (i) => {
    setCurrentIndex(i);
  };
  useEffect(() => {
    setFilteredBots(botData?.filter((bot) => bot?.category == currentIndex));
  }, [currentIndex]);
  return (
    <>
      {isOpen ? (
        <>
          <div className={styles.overlay}>
            <div className={`${styles.modal} ${animation['slit-in-vertical']}`}>
              <div
                onClick={() => setIsOpen(false)}
                style={{
                  zIndex: "10000",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  cursor: "pointer",
                }}
              >
                <IoMdCloseCircleOutline size={30} />
              </div>

              <div key={ModalData.id} className={botStyles.card}>
                <img
                  src={ModalData?.image}
                  alt={ModalData.name}
                  className={botStyles.image}
                />
                <h2 className={botStyles.name}>{ModalData.name}</h2>
                <p className={botStyles.role}>{ModalData.role}</p>
                <p className={botStyles.roleTitle}>
                  <strong>Role:</strong>
                </p>
                <p className={botStyles.description}>
                  {" "}
                  {ModalData.description}
                </p>
                <p className={botStyles.replacedRole}>
                  <strong>Replaced Role:</strong> {ModalData.replacedRole}
                </p>
                <div className={botStyles.details}>
                  {ModalData?.details.map((detail, i) => (
                    <span key={i} className={botStyles.detail}>
                      Task Time {i === 0 ? "Human" : ModalData.name}: {detail}
                    </span>
                  ))}
                </div>
                <Spacer height={"1rem"}/>
                <button
                  className={botStyles.tryNow}
                  onClick={() => {navigate("/contactus");dispatch({type:"ADD_BOTNAME",payload:ModalData?.id});}}
                >
                  Try Now
                </button>
              </div>
            </div>{" "}
          </div>
        </>
      ) : (
        <></>
      )}
      <div className={`${styles.cardSelector}`}>
        <div style={{ padding: "2rem 2rem 0rem 2rem" }}>
          <div
            className={`${styles["head-lg"]} ${
              styles[isMobile ? "center" : ""]
            } ${styles[isMobile ? "w-100" : "w-80"]} ${styles["center"]} ${
              styles["m-auto"]
            }`}
          >
            With 37 Thematic Healthmates, Ready for Hire
          </div>
          <Spacer height={"2rem"} />
          <div className={`${styles["gradiant-tabs"]} ${styles.wrap}`}>
            {catergories?.map((btn, i) => (
              <button
                onClick={() => handleIndexChange(i)}
                style={{
                  flexDirection: "column",
                  borderRadius: "1rem",
                  minWidth: "300px",
                  padding: "1rem",
                }}
                className={
                  styles[currentIndex == i ? "white-button" : "outline-button"]
                }
              >
                <div
                  style={{ fontSize: "1.2rem" }}
                  className={styles["head-sm"]}
                >
                  <b>{btn?.title}</b>
                </div>
              </button>
            ))}
          </div>
        </div>
        <Spacer height={"2rem"} />

        <div data-aos="fade-right">
          <Swiper
            effect={"coverflow"}
            initialSlide={3}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={isMobile ? 1 : isDesktop ? 2 : 4}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 200,
              modifier: 0.5,

              //   slideShadows: true,
            }}
            loop
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            spaceBetween={100}
            pagination={false}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="mySwiper"
          >
            {filteredBots?.map((data, i) => (
              <SwiperSlide>
                <BotCard
                  setIsOpen={setIsOpen}
                  setModalData={setModalData}
                  data={data}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <Spacer height={"1rem"} /> */}
          <div style={{ gap: "1.1rem" }} className={styles["flex-center"]}>
            <div
              style={{ cursor: "pointer" }}
              className="custom-prev absolute left-0 bottom-0  z-10 cursor-pointer text-3xl"
            >
              <IoIosArrowDropleftCircle size={40} />
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="custom-next absolute right-0 z-10 cursor-pointer text-3xl"
            >
              <IoIosArrowDroprightCircle size={40} />
            </div>
          </div>
          <Spacer height={"1rem"} />
          {/* <div
            className={`${styles["outline-container"]} ${styles[isMobile?"w-80":"w-50"]}
          ${styles["m-auto"]}
          `}
          >
            <div
            style={{ width:isMobile?"100%":"80%" }}
              className={`${styles["head-md"]}}  ${styles["center"]} ${styles["w-50"]}}`}
            >
              {" "}
              Solve unique healthcare challenges and create revenue source by
              using our easy drag & drop HealthMate Creator
            </div>
            <button
            onClick={()=>navigate("/subscribe")}
              style={{
                padding: isMobile?"0.7rem 2rem":"1.5rem 2rem",
                borderRadius: "2rem",
                maxWidth: "fit-content",
              }}
              className={styles["white-button"]}
            >
              <b>Create HealthMate</b>
            </button>
          </div> */}

          <div
            style={{
              width: "fit-content",
              padding: ".5rem 3rem",
              borderRadius: "2rem",
            }}
            onClick={() => navigate("/profile")}
            className={`${styles["active-tab"]} ${styles["m-auto"]} `}
          >
            View All
          </div>
          <Spacer height={"2rem"} />
        </div>
      </div>
    </>
  );
}
