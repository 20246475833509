import React from "react";
import { Route,  Routes } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Research from "../pages/Research";
import Career from "../pages/Career";
import VoiceToText from "../components/common/Voice";
import ScrollToTop from "../components/common/ScrollToTop";
import ProfileData from "../components/Profile/ProfileData";
import WhyHealthmates from "../pages/WhyHealthmates";
import AiModel from "../pages/AiModel";
import SafeAI from "../components/SafeAI/SafeAI";
import PartnerComponent from "../components/Partner/PartnerComponent";
import FoundersSection from "../components/Founder/FoundersSection";
import Subscribe from "../pages/Subscribe";
import Unsubscribe from "../components/Unsubscribe/Unsubscribe";
import RequestDemo from "../pages/RequestDemo";


        
const AllRoutes = () => {

    return (
        <div style={{ maxWidth:"1480px",margin:"auto" }}>
        
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contactus" element={<Contact/>} />
            <Route path="/research" element={<Research/>} />
            <Route path="/career" element={<Career/>} />
            <Route path="/voice" element={<VoiceToText/>} />
            <Route path="/whyHealthmates" element={<WhyHealthmates/>} />
            <Route path="/AiModel" element={<AiModel/>} />
            <Route path="/safeAI" element={<SafeAI/>} />
            <Route path="/founders" element={<FoundersSection/>} />
            <Route path="/partners" element={<PartnerComponent/>} />
            <Route path="/subscribe" element={<Subscribe/>} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/reqDemo" element={<RequestDemo/>} />
            <Route path="/profile" element={<ProfileData/>} />
        </Routes>
        </div>
    );
};
export default AllRoutes;