import React, { useState } from "react";
import styles from "../../styles/main.module.css";
import nurse from "../../assets/Home Page/grid-nurse-img.png";
import tick from "../../assets/Home Page/green-tick.png";
import Spacer from "../common/Spacer";
import { mobileDimension, tabletDimension } from "./CardSelector";
import { useMediaQuery } from "react-responsive";

const FeaturesList = () => {
  const isMobile = useMediaQuery(mobileDimension);
  const isDesktop = useMediaQuery(tabletDimension);
  const [features, setFeatures] = useState([
    {
      name: "HealthMates are not just AI agents but AI teammates, an AI Employee, tailored for healthcare.",
    },
    {
      name: "Focused on outcomes rather than isolated tasks.",
    },
    {
      name: "Collaborate across systems, adapt to specific roles, and ensure actionable outcomes",
    },
    {
      name: "Combine bedside manners, holistic understanding, and continuous learning, to provide traceable, proactive action and support",
    },
  ]);
  return (
    <div  className={`${styles?.center} ${styles['cover-featureslist']}`}>
        
        <div className={`${styles["head-lg"]}  `}>More Than AI Agents</div>
        <Spacer height={"2rem"}/>
      <div
        style={{ padding: isMobile ? "0rem 1rem" : "" }}
        className={`${styles["head-sm"]}  ${styles["font-w-4"]}`}
      >
        A.I Agents Perform Tasks – HealthMates.AI Builds Outcomes; Like an
        employee
      </div>
      <Spacer height={"1rem"} />
      <div
        className={` ${styles?.["flex-between"]} ${styles?.["wrap"]}   ${styles?.["mobileWithNone"]} ${styles?.["w-90"]} ${styles?.["m-auto"]} ${styles?.["m-t-3"]} `}
      >
        <div
          className={`  ${styles?.[isMobile ? "w-90" : "w-50"]} ${
            styles?.[isMobile ? "m-auto" : ""]
          }  `}
        >
          {features?.map((f, index) => (
            <div
              style={{ alignItems: "start", textAlign: "left", gap: isMobile?".9rem":"2rem" }}
              className={`${styles?.["flex-gap"]} ${styles?.[isMobile?"head-xsm":"head-sm"]} ${styles?.[""]} ${styles?.[isMobile?"m-t-3":"m-t-4"]}  ${styles["font-w-4"]}`}
            >
              <div
                style={{ border: "none", padding: isMobile?".7rem":".8rem" }}
                className={`${styles["value-circle"]} ${styles[isMobile?"head-xsm":"head-sm"]} ${styles["font-w-4"]} ${styles["gradiant-button"]}`}
              >
                0{index + 1}
              </div>
              {/* <img style={{ width: "27px" }} src={tick} alt="" /> */}
              {f?.name}
            </div>
          ))}
        </div>
        {isMobile ? <Spacer height={"2rem"} /> : <></>}
        <div
          style={{
            width: isMobile ? "70%" : "30%",
            marginTop: isMobile ? "2rem" : "",
          }}
          className={`${styles["w-35"]} ${styles?.[isMobile ? "m-auto" : ""]}`}
        >
          <img width="100%" src={nurse} alt="" />
        </div>
      </div>
    </div>
  );
};
export default FeaturesList;
