import React, { useState } from "react";
import styles from "../../styles/main.module.css";
import ReactCardFlip from "react-card-flip";
const FeatureCard = ({
  img = "",
  title = "",
  subTitle = "",
  isGradiant = false,
  i = null,
  min_height = "200px",
  isKeyDifference = false,
  isCenter = false,
  backgroundColor = "#3C3C3C",
  border = false,
  flip_description = "",
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const flip = () => {
    if (flip_description !== "") {
      setIsFlipped(true);
    }
  };

  return (
    <ReactCardFlip
      style={{ height: "100%" }}
      isFlipped={isFlipped}
      flipDirection="horizontal"
    >
      <div
        style={{
          alignItems: isCenter ? "center" : "start",
          minHeight:min_height,
          cursor: flip_description ? "pointer" : "",
          border: border ? "1px solid white" : "none",
          backgroundColor: border
            ? "transparent"
            : backgroundColor
            ? backgroundColor
            : "",
        }}
        onClick={flip}
        className={`${
          styles?.[isGradiant ? "feature-card-gradiant" : "feature-card"]
        } ${styles[!isKeyDifference ? "" : ""]}  `}
      >
        <div
          style={{
            width:
              window.innerWidth > 700
                ? isKeyDifference
                  ? "20%"
                  : "80%"
                : "68%",
            maxWidth: "50px",
            minWidth: "50px",
          }}
          className={`${styles?.["w-80"]}  `}
        >
          {/* <img style={{ width:"100%",padding:i==1 ? "13rem 0rem":i==2?"2rem 0rem":i==3?"4rem 0rem":"0" }} src={img} alt="" /> */}
          <img width="100%" src={img} alt="" />
        </div>

        <div className={` ${styles?.["feature-content"]}`}>
          <div
            className={`${styles?.["head-md-sm"]} ${
              styles?.[isCenter ? "center" : "left"]
            }`}
          >
            <b>{title}</b>
          </div>
          <div
            // style={{ fontWeight: "300", fontSize: ".8rem" }}
            className={` ${styles?.["fade-content3"]}  ${
              styles?.[isCenter ? "center" : "left"]
            }  ${styles?.["p-t-1"]}
        ${styles?.["font-w-4"]}`}
          >
            {subTitle}
          </div>
        </div>
      </div>

      <div
        style={{
          cursor: flip_description ? "pointer" : "",
          border: border ? "1px solid white" : "none",
          minHeight:min_height,

          backgroundColor: border ? "transparent" : "",
        }}
        onClick={() => setIsFlipped(!isFlipped)}
        className={`${
          styles?.[isGradiant ? "feature-card-gradiant" : "feature-card-flip"]
        } ${styles[!isKeyDifference ? "" : ""]}  `}
      >
        <div
          style={{
            maxWidth: "30px",
            minWidth: "30px",
          }}
        >
          {/* <img style={{ width:"100%",padding:i==1 ? "13rem 0rem":i==2?"2rem 0rem":i==3?"4rem 0rem":"0" }} src={img} alt="" /> */}
          <img width="100%" src={img} alt="" />
        </div>

        <div className={` ${styles?.["feature-content"]}`}>
          {/* <div className={`${styles?.["head-sm"]} ${styles?.[isCenter?"center":""]}`}><b>{title}</b></div> */}
          <div
            // style={{ fontWeight: "300", fontSize: ".8rem" }}
            className={` ${styles?.["fade-content3"]}  ${styles?.["p-t-1"]}
        ${styles?.["font-w-4"]}`}
          >
            {flip_description}
          </div>
        </div>
      </div>
    </ReactCardFlip>
  );
};
export default FeatureCard;
