import React, { useState } from "react";
// import { slide as Menu } from 'react-burger-menu'
import styles from "../../styles/main.module.css";
import logo from "../../assets/healthmates-new-logo.png";
import { redirect, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { useMediaQuery } from "react-responsive";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";

const menus = [
  {
    
    name: "Home",
    route: "/",
  },

  {
    name: "About Us",
    route: "/about",
    subgroups: [
      { name: "Why HealthMates", route: "/whyHealthmates" },
      // { name: "Our Partners", route: "/partners" },
      { name: "Meet the Founder", route: "/founders" },
    ],
  },
  { name: "Partners", route: "/partners" },
  // { name: "Healthmates", route: "/profile" },
  // { name: "Contact Us", route: "/contactus" },
  // { name: "Safe AI", route: "/safeAi" },

  { name: "AI Model", route: "/AiModel" },
  { name: "Safe AI", route: "/safeAI" },


  { name: "Blogs", route: "/research" },
];
const Navbar = () => {
  const isMobileOrTab = useMediaQuery({ maxWidth: "1024px" });
  console.log(isMobileOrTab);
  const navigate = useNavigate();
  return (
    <div className={`${styles?.["flex-between"]} ${styles?.["navbar"]}`}>
      <div className={`${styles["flex-gap"]} ${styles["gap-5"]} ${styles["w-100"]}`}>
      <div
          onClick={() => navigate("/")}
          className={`${styles?.["head-md"]} ${styles?.["gradiant-pink-col"]} ${styles.pointer} ${styles?.[""]} `}
        >
          HealthMates.AI
        </div>
      <div
        style={{ gap: "6rem" }}
        className={`${styles?.[isMobileOrTab?"":"flex-between"]} ${styles[isMobileOrTab?"":"subNavbar"]} ${styles?.["w-100"]}`}
      >
        {/* <div
         
          style={{ width: isMobileOrTab ? "130px" : "72%", maxWidth: "172px" }}
          className={`  ${styles?.["pointer"]}  `}
        >
          <img style={{ width: "100%" }} src={logo} alt="" />
        </div> */}
       
        {!isMobileOrTab ? (
          <div
            style={{ gap: isMobileOrTab?"1rem":"2rem",minWidth:"80%"}}
            className={`${styles?.["flex-gap"]} ${styles?.[""]}`}
          >
            {menus?.map((menu) => (
              <>
                <div
                 
                  className={` ${styles?.["pointer"]} 
                   ${
                     window.location.pathname != menu?.route
                       ? styles?.["tab"]
                       : styles?.["active-tab"]
                   } 
                   ${styles?.["dropdown"]}
                  `}
                  style={{zIndex:"1"}}
                >
                  {" "}
                  <div  onClick={() => navigate(menu?.route)}>{menu?.name}</div>
                  
                  {menu?.subgroups?.length > 0 ? (
                    <>
                      <div className={`${styles?.["dropdown-content"]}`}>
                        {menu?.subgroups?.map((sub) => (
                          <div
                  style={{zIndex:"1000"}}
                            
                  onClick={() => navigate(sub?.route)}
                            className={` ${
                              window.location.pathname ==
                              sub?.route
                                ? styles?.["dropdown-item-active"]
                                : styles?.["dropdown-item"]
                            } `}
                          >
                            
                            {sub?.name}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ))}
          </div>
        ) : (
          <></>
        )}<button style={{ borderRadius:"2rem" }} onClick={()=>navigate("/contactus")} className={styles['white-button']}>Say Hi!</button>
      </div>
      
      </div>
      {/* <button
        onClick={() => navigate("/contactus")}
        className={`${styles?.["gradiant-button"]} ${styles?.["primary-color"]} ${styles?.["req-button"]}`}
      >
        Request Demo
      </button> */}

      {isMobileOrTab ? <Hamburger /> : <></>}
    </div>
  );
};
export default Navbar;

const Hamburger = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div style={{ marginLeft: "1rem" }} onClick={toggleDrawer}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 16V13.3333H20V16H0ZM0 9.33333V6.66667H20V9.33333H0ZM0 2.66667V0H20V2.66667H0Z"
            fill="white"
          />
        </svg>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className={styles.menus}
      >
        <div className={`${styles?.["mainMenu"]} ${styles?.["head-md"]} `}>
          Main Menu
        </div>
        <hr style={{ margin:0 }} />
        {/* <div
          onClick={() => navigate("/")}
          className={` ${styles?.["pointer"]}  ${
            window.location.pathname != "/"
              ? styles?.["menu"]
              : styles?.["menuActive"]
          } `}
        >
          Home
        </div> */}
        {menus?.map((menu) => (
          <>
            <div
              onClick={() => navigate(menu?.route)}
              className={` ${styles?.["pointer"]}  ${
                window.location.pathname != menu?.route
                  ? styles?.["menu"]
                  : styles?.["menuActive"]
              } `}
            >
              {menu?.name}
            </div>
            {menu?.subgroups?.map((sub) => (
              <div
                onClick={() => navigate(sub?.route)}
                className={` ${styles?.["pointer"]}  ${
                  window.location.pathname != sub?.route
                    ? styles?.["menu"]
                    : styles?.["menuActive"]
                } `}
              >
                {sub?.name}
              </div>
            ))}
          </>
        ))}
      </Drawer>
    </>
  );
};
