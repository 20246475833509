import { useState } from "react";
import styles from "./Unsubscribe.module.css";
import { useParams, useSearchParams } from "react-router-dom";
import { delete_subscription } from "../../api/Form/FormApi";
import Error from "../common/Error";

export default function Unsubscribe() {
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [searchParams]=useSearchParams()
  const email=searchParams.get("email")
  const [isError,setIsError]=useState(false)
  // const {email}=useParams();
  const handleUnsubscribe = async() => {
    const res=await delete_subscription({email:email,flag:0});
    if (res) {
    setUnsubscribed(true);
    }
    else{
setIsError(true)
    }
  };

  const handleStay = () => {
    window.location.href = "/"; // Change to your desired redirect page
  };

  return (
    <div className={styles.container}>
      {isError ? (
        <Error />):<>
      {!unsubscribed ? (
        <div className={styles.confirmation}>
          <h2>Are you sure you want to unsubscribe?</h2>
          <p>You might miss on best offers and notifications</p>
          <div className={styles.buttons}>
            <button className={styles.stay} onClick={handleStay}>Stay</button>
            <button className={styles.unsubscribe} onClick={handleUnsubscribe}>Unsubscribe</button>
          </div>
        </div>
      ) : (
        <div className={styles.successContainer}>
          <h1 className={styles.logo}>HealthMates.<span>AI</span></h1>
          {/* <div className={styles.checkmark}>✔️</div> */}
          <svg width="50" height="50" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.7535 127.5C75.2701 127.501 86.5719 124.382 96.4581 118.475C106.344 112.568 114.446 104.093 119.902 93.9506C125.357 83.8083 127.964 72.3775 127.444 60.8726C126.924 49.3678 123.298 38.2186 116.949 28.6096L66.2681 84.9221C63.9008 87.5529 60.6344 89.2024 57.112 89.5459C53.5895 89.8894 50.0661 88.9019 47.2352 86.7779L24.0868 69.4167C22.5839 68.2895 21.5904 66.6115 21.3247 64.7517C21.059 62.892 21.543 61.0029 22.6702 59.5C23.7973 57.9971 25.4754 57.0035 27.3351 56.7379C29.1948 56.4722 31.0839 56.9562 32.5868 58.0833L55.7352 75.4446L107.769 17.6375C100.231 10.4416 91.044 5.20342 81.0121 2.38157C70.9801 -0.440275 60.4093 -0.75973 50.2252 1.45118C40.0412 3.66209 30.5544 8.33594 22.5956 15.0635C14.6367 21.7911 8.44854 30.3672 4.57279 40.041C0.697047 49.7147 -0.748049 60.1911 0.364034 70.5529C1.47612 80.9147 5.11147 90.8459 10.9518 99.4769C16.7921 108.108 24.6593 115.175 33.8645 120.061C43.0697 124.946 53.3322 127.501 63.7535 127.5Z" fill="#FF7031"/>
</svg>

          <h2 className={styles.message}>You have successfully unsubscribed to HealthMates</h2>
          <p>
            If you would like to re-subscribe, please click here <a href="/subscribe" className={styles.resubscribe}>Re-subscribe</a>
          </p>
        </div>
      )}
      </>}
    </div>
  );
}
