import styles from "../../styles/main.module.css";
import React, { useEffect, useRef, useState } from "react";
import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
import { mobileDimension, tabletDimension } from "./ContentSlider";

const SliderContent = ({ data }) => {
    const isMobile=useMediaQuery(mobileDimension)
    const isTablet=useMediaQuery(tabletDimension)
  return (
    <div
      style={{ gap: "2rem", alignItems: "start",padding:isMobile ||isTablet?".5rem":"1rem 3rem"}}
      className={`${styles["flex-between"]} ${styles["wrap"]} ${styles["p-2"]}`}
    >
      <div
        style={{ gap: isMobile?"1rem":"3rem" }}
        className={`${styles["flex-column-str"]} ${styles[isMobile ||isTablet?"w-100":"w-70"]}`}
      >
        <span
        
          style={{ maxWidth: "fit-content",borderRadius:"1.5rem" }}
          className={styles["white-button"]}
        >
          {data?.tagline}
        </span>
        <div>
          <div className={styles['head-md']}>
            <b>The Problem</b>
          </div>
          < Spacer height=".5rem"/>
          
          <div className={styles["fade-content2"]}>{data?.problem}</div>
        </div>
        <div>
          <div className={styles['head-md']}>
            <b>The HealthMates Solution</b>
          </div>
          < Spacer height=".5rem"/>

          <div className={styles["fade-content2"]}>{data?.solution}</div>
          {data?.solutions?.map((solution) => (
            <div className={styles["fade-content2"]}> {solution}</div>
          ))}
        </div>
      </div>
      <div style={{ width: isMobile ?"100%":isTablet?"50%":"25%" }} className={styles[isTablet?'m-auto':""]}>
        <img width="100%" src={data?.image} alt="" />
      </div>
    </div>
  );
};
export default SliderContent;
