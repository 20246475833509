import React, { useState } from "react";
import MainCover from "../components/home/MainCover";
import styles from "../styles/main.module.css";
import animation from "../styles/animation.module.css";
import FeedBack from "../components/home/Feedback";
import profile from "../assets/profile-images/image 2620.png";
import LanguageSection from "../components/home/LanguageSection";
import ProtectionSection from "../components/home/ProtectionSection";
import GoalSection from "../components/home/GoalSection";
import FeaturesSection from "../components/home/FeaturesSection";
import SecuritySection from "../components/home/SecuritySection";
import Media from "../components/home/Media";
import Spacer from "../components/common/Spacer";
import { useMediaQuery } from "react-responsive";
import CoverWithDescription from "../components/home/CoverWithDescription";
import FeaturesRing from "../components/home/FeaturesRing";
import FeaturesList from "../components/home/FeaturesList";
import TextBanner from "../components/home/TextBanner";
import AIFeatures from "../components/home/AIFeatures";
import CardSelector, { mobileDimension, tabletDimension } from "../components/home/CardSelector";
import KeyDifferences from "../components/home/KeyDifferences";
import FlexContainer from "../components/home/FlexContainer";
import listening from "../assets/Home Page/icons/listening.png";
import network from "../assets/Home Page/icons/network.png";
import assessing from "../assets/Home Page/icons/Assessing.png";
import Reflecting from "../assets/Home Page/icons/reflecting.png";
import react from "../assets/Home Page/icons/react.png";
import secure from "../assets/Home Page/icons/secure (1).png";
import secure2 from "../assets/Home Page/icons/secure (2).png";
import tree from "../assets/Home Page/icons/tree.png";
import stethoscope from "../assets/Home Page/icons/stethoscope.png";
import welness from "../assets/Home Page/icons/wellness.png";
import star from "../assets/Home Page/icons/graidant-star.png";
import heart from "../assets/Home Page/icons/gradient-heart.png";
import money from "../assets/Home Page/icons/gradiant-money.png";
import users from "../assets/Home Page/icons/gradient-users.png";
import RequestDemo from "../components/home/RequestDemo";
import FeaturesWithCard from "../components/common/FeaturesWithCard";
import gradiantUsers from "../assets/Home Page/icons/gradiant-users.png";
import gradiantHospitals from "../assets/Home Page/icons/gradiant-hospital.png";
import gradiantListening from "../assets/Home Page/icons/gradiant-listening.png";
import gradiantGraph from "../assets/Home Page/icons/gradiant-graph.png";
import gradiantMoney from "../assets/Home Page/icons/gradiant-money.png";
import gradiantTime from "../assets/Home Page/icons/gradiant-time.png";
import gradiantTick from "../assets/Home Page/icons/gradiant-tick.png";
import gradiantGrowth from "../assets/Home Page/icons/gradiant-growth.png";
import gradiantsecure from "../assets/Home Page/icons/gradiant-security.png";
import scale from "../assets/Home Page/icons/scale.png";
import growth from "../assets/Home Page/icons/garden_growth-chart-fill-16.png";
import whiteUsers from "../assets/Home Page/icons/users.png";

import ContentSlider from "../components/WhyHealthmates/ContentSlider";
import operation from "../assets/Home Page/operation.png";
import doctor from "../assets/Home Page/doctor.png";

import nurse from "../assets/Home Page/nurse.png";
import consult from "../assets/Home Page/consult.png";
import BeingRecorgnized from "../components/home/BeingRecorgnised";
import RevolutionModal from "../components/home/RevolutionModal";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  const isTab=useMediaQuery(tabletDimension)
  const [isOpen, setIsOpen] = useState(false);
  const navigate=useNavigate()
  
  const features = [
    {
      img: listening,
      title: "Listening",
      description:
        "Capturing multimodal inputs, like humans, to understand user needs.",
    },
    {
      img: network,
      title: "Inferring",
      description: "Context and data aware reasoning for accurate decisions.",
    },
    {
      img: react,
      title: "Acting",
      description:
        "Proactively executing tasks and collaborating seamlessly with AI and human counterparts.",
    },
    {
      img: Reflecting,
      title: "Reflecting",
      description: " Learning from interactions for continuous improvement.",
    },
    {
      img: assessing,
      title: "Assessing",
      description: "Evaluating outcomes and generating actionable insights.",
    },
  ];
  const featuresData = {
    // title:"The Next Evolution: Build Your Own HealthMate",
    // desc:"Soon, we will introduce a revolutionary AI configurator, allowing healthcare providers to:",
    features: [
      {
        title: "AI Teammates, Not Just AI Tools",
        img: gradiantUsers,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
        flip_description:
          "Designed to complement human efforts, seamlessly integrating into teams, owning specific roles or workflows. Whether automating documentation or assisting in patient care, they collaborate with other human & AI teammates.",
      },
      {
        title: "Deep Integration with Hospital IT",
        img: gradiantHospitals,
        // subTitle:"Monetize custom-built AI solutions and make them available for adoption worldwide.",
        flip_description:
          "HealthMates are fully integrated with your Health IT systems such as EHR, CRM, ERP, ensuring seamless data flow. Enabling hospitals to automate workflows, manage patient journeys, and maintain compliance while enhancing operational efficiency.",
      },
      {
        title: " Outcome-Driven, Not Just Task-Based",
        img: gradiantListening,
        // subTitle:" No coding required! Deploy AI teammates in days, not months.",
        flip_description:
          "HealthMates are designed to achieve measurable healthcare outcomes, not just complete tasks. From improving patient satisfaction to reducing operational inefficiencies, their focus is on delivering tangible results.",
      },
      {
        title: "Multimodal AI",
        img: gradiantGraph,
        // subTitle:"Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
        flip_description:
          "HealthMates operate using voice, vision, and text depending on the usecase. From interpreting patient records to analysing diagnostic visuals or responding to voice commands, they adapt to diverse healthcare scenarios for maximum impact and usability",
      },
      {
        title: "Scalable & Cost-Effective",
        img: gradiantMoney,
        // subTitle:"Monetize custom-built AI solutions and make them available for adoption worldwide.",
        flip_description:
          "Easily deployable and infinitely scalable, HealthMates outperform their human counterparts in productivity while reducing costs. Hospitals can onboard HealthMates overnight, meeting fluctuating demands",
      },
      {
        title: "Human Like",
        img: gradiantsecure,
        // subTitle:" No coding required! Deploy AI teammates in days, not months.",
        flip_description:
          "HealthMates mimic human intelligence. Trained for healthcare, they combine contextual understanding with precise execution, ensuring empathetic and efficient interaction with patients and professionals alike.",
      },
    ],
  };
  const featuresData2 = {
    // title:"The Next Evolution: Build Your Own HealthMate",
    // desc:"Soon, we will introduce a revolutionary AI configurator, allowing healthcare providers to:",
    features: [
      {
        title: "Design & Deploy Custom AI Teammates",
        img: scale,
        subTitle:
          "Using an intuitive drag-and-drop AI builder to fit unique operational needs.",
      },
      {
        title: "Commercialize AI Teammates via the HealthMates Store",
        img: whiteUsers,
        subTitle:
          "Monetize custom-built AI solutions and make them available for adoption worldwide.",
      },
      {
        title: " Instantly Scale & Adapt",
        img: growth,
        subTitle:
          " No coding required! Deploy AI teammates in days, not months.",
        // flip_description:
        //   "HealthMates are designed to achieve measurable healthcare outcomes, not just complete tasks. From improving patient satisfaction to reducing operational inefficiencies, their focus is on delivering tangible results.",
      },
    ],
  };
  const featuresData3 = {
    // title:"The Next Evolution: Build Your Own HealthMate",
    // desc:"Soon, we will introduce a revolutionary AI configurator, allowing healthcare providers to:",
    features: [
      {
        title: "Staffing Costs Reduced by 25-30%",
        img: gradiantMoney,
        subTitle: "AI teammates augment and support human workers",
      },
      {
        title: "Administrative Tasks Completed 50% Faster",
        img: gradiantTick,
        subTitle: "AI-driven automation streamlines workflows.",
      },
      {
        title: "Revenue Growth of 10-15%",
        img: gradiantGrowth,
        subTitle: "Optimized appointments, claims, and billing",
        // flip_description:
        //   "HealthMates are designed to achieve measurable healthcare outcomes, not just complete tasks. From improving patient satisfaction to reducing operational inefficiencies, their focus is on delivering tangible results.",
      },
      {
        title: "Smarter scheduling & AI-based patient interactions",
        img: gradiantTime,
        subTitle: "Smarter scheduling & AI-based patient interactions",
      },
    ],
  };
  const features2 = [
    {
      title: "Architected for Hallucination Mitigation",
      img: tree,
      isKeyDifference: true,
      subTitle:
        "Ensures accurate, evidence-based responses aligned with clinical guidelines",
    },
    {
      title: "Built-in Empathy & Bedside Manners",
      img: welness,
      isKeyDifference: true,
      subTitle:
        "Ensuring every interaction feels personal, human, and compassionate.",
    },
    {
      title: "Transparency and Explainable AI (XAI) Outcomes",
      img: tree,
      isKeyDifference: true,
      subTitle:
        "Outputs include decision rationale for recommendations and actions.",
    },
    {
      title: "HIPAA, PDP, GDPR, and SOC 2 Compliant",
      img: secure,
      isKeyDifference: true,
      subTitle:
        "Ensuring Patient Data Privacy Protection via Google Certified Services.",
    },
    {
      title: "Global Medical Coding Standards Compliant",
      img: secure2,
      isKeyDifference: true,
      subTitle:
        "Adhering to global coding standards like ICD, SNOMED CT, GMDN, LOINC",
    },
    {
      title: "Fine-tuned with Medical Knowledge Guardrails",
      img: stethoscope,
      isKeyDifference: true,
      subTitle: "Validates information against authoritative medical datasets",
    },
  ];
  const keyDifferenceData = {
    title: "Key Differentiators",
    desc: "Healthmates.AI stands out with its Incredibly Human AI Digital Workers, offering empathetic, human-like interactions. It seamlessly integrates with HIMS and EHR systems, unlocks real-time insights from unstructured data, and ensures compliance with global standards like HIPAA, GDPR, and PDP, while providing scalable, adaptable solutions for diverse healthcare environments.",
    features: [
      {
        title: "Multi Modal Artificial Intelligence",
        img: star,
        subTitle: "Combines voice, text, and vision to understand and act",
      },
      {
        title: "Role & Team oriented Arichitecture",
        img: users,
        subTitle:
          "Replaces or Augments Roles in Teams rather than isolated tasks in workflows",
      },
      {
        title: "Stability & Cost Efficiency",
        img: money,
        subTitle:
          "Team-Mates who can process large workloads with predictable costs",
      },
      {
        title: "Pre-built Healthcare Specialization",
        img: heart,
        subTitle:
          "Healthcare-first pre-built role expertise to address specific challenges",
      },
    ],
  };
  const sliderData = [
    {
      tagline: "Overcoming Staff Shortages",
      problem:
        "Global healthcare is facing an acute shortage of professionals. The WHO predicts a shortfall of 10 million healthcare workers by 2030",
      solution:
        "AI-powered teammates fill operational gaps by handling patient queries, triage, follow-ups, medical documentation, and more—freeing human professionals to focus on care",
      solutions: [],
      image: doctor,
    },
    {
      tagline: "Enhancing Patient Experience",
      problem:
        "Long wait times, delayed responses, and fragmented patient journeys lead to frustration and poor healthcare outcomes",
      solution: "",
      solutions: [
        "● AI-powered scheduling ensures appointments are optimized, reducing wait times.",
        "● Virtual concierges answer patient queries instantly.",
        "● Personalized follow-ups improve post-hospital care and reduce readmissions",
      ],
      image: nurse,
    },
    {
      tagline: "Reducing Administrative Burdens",
      problem:
        "Doctors and nurses spend up to 40% of their time on documentation instead of patient care.",
      solution: "",
      solutions: [
        "● Siya, the AI Medical Scribe – Automates clinical documentation, allowing doctors to focus on patients.",
        "● Medusa, the AI Medical Coder – Ensures accurate billing & faster claim approvals.",
        "● Andi, the AI Nursing Assistant – Manages handover documentation and shift reports.",
      ],
      image: operation,
    },
    {
      tagline: "Optimizing Revenue & Insurance Processing",
      problem:
        "Hospitals lose millions annually due to insurance claim denials, revenue leakage, and inefficiencies in billing.",
      solution: "",
      solutions: [
        "● Salvator, the AI Insurance Specialist – Speeds up claims processing & validation.",
        "● Ana, the AI Financial Analyst – Provides actionable insights to boost revenue & cut costs.",
      ],
      image: consult,
    },
  ];

  return (
    <div
      style={{ gap: isMobile ? "4rem" : "6rem" }}
      data-aos="fade-down"
      className={`${styles?.["flex-column-str"]} ${styles?.[""]}  `}
    >
      <RevolutionModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <MainCover />

      {/* <Spacer height={isMobile ? "2rem" : "4rem"} /> */}
      {/* <div
        data-aos="fade-left"
        style={{ gap: "10%" }}
        className={`${styles["flexMobileWrap"]} ${styles?.["flex-gap"]} ${styles?.["w-80"]} ${styles?.["m-auto"]}  `}
      >
        <h1 className={`${styles?.["w-90"]} ${styles?.[""]}  `}>
          Introducing fully human capable AI Agents powering the best healthcare
          providers
        </h1>
        <FeedBack
          name="Mr Sunel Satya Kapur "
          designation="CEO, Sharda Care HealthCity"
          feedback="“It’s like having a perfect employee, that works 24 hours a day, exactly how you trained it - becoming a force multiplier for the entire organization”"
          profile={profile}
        />
      </div> */}
      <div
        // style={{ marginTop: isMobile ? "-53px" : "0" }}
        data-aos="fade-right"
      >
        {" "}
        <CoverWithDescription setIsOpen={setIsOpen} />
      </div>
      <div data-aos="fade-left">
        <RequestDemo />
      </div>
      {/* <div style={{ marginTop:isMobile?"-53px":"0" }} data-aos="fade-right">
        {" "}
        <CoverWithDescription />
      </div> */}
      <div data-aos="fade-right">
        {" "}
        <FeaturesRing />{" "}
      </div>
      <div data-aos="fade-left">
        <FeaturesList />
      </div>
      <div data-aos="fade-right" className={styles.center}>
        <div className={`${styles["head-lg"]} ${styles["m-auto"]} ${styles["w-80"]}`}>
          Operating as AI TeamMates, not just AI Agents
        </div>
        <Spacer height={"2rem"} />
        <div className={styles["cover-AiTemates"]}>
          <FeaturesWithCard
            isFlip={true}
            isCenter={true}
            min_height="212px"
            grid={isMobile ? 1 :isTab?2: 3}
            data={featuresData}
          />
        </div>
      </div>

      <CardSelector />
      <div data-aos="fade-left">
        <ContentSlider data={sliderData} />
      </div>

      {/* {!isMobile ? (
        <div data-aos="fade-left">
          <TextBanner
            heading={
              "Healthmates.AI are AI-powered Healthcare Team Mates, AI Employees designed optimize hospital operations."
            }
          />
        </div>
      ) : (
        <></>
      )} */}
      {/* <div data-aos="fade-right">
        <AIFeatures
          data={{
            first_title: "Designed for AGI-Like ",
            second_title: "Intelligence",
            description: "On the Cusp of Artificial General Intelligence (AGI)",
            features: features,
          }}
        />
      </div> */}

      <div
        data-aos="fade-right"
        style={{ gap: "2rem" }}
        className={`${styles.center} ${styles["cover-evolution"]} ${styles.cove} ${styles["flex-column"]}`}
      >
        <div className={`${styles["head-lg"]}`}>
          Ready for the Next Evolution:
        </div>
        <div className={`${styles["head-md"]}`}>Build Your Own HealthMate</div>
        {/* <Spacer height={"2rem"} /> */}
        <div className={`${styles["head-sm"]} ${styles["font-w-4"]}`}>
          Soon, we will introduce a revolutionary AI <br /> configurator,
          allowing healthcare providers to:
        </div>
        <div
        onClick={()=>navigate("/subscribe")}
          className={`${styles["white-button3"]} ${styles[isMobile?"head-md":"head-lg"]} ${styles["flex-gap"]}`}
        >
          Create Custom HealthMates{" "}
          <svg
            width={isMobile?"30":"50"}
            height={isMobile?"30":"50"}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="25"
              cy="25"
              r="25"
              fill="url(#paint0_linear_9870_2523)"
            />
            <path
              d="M19.7059 18.9414H18.4706C17.8153 18.9414 17.1869 19.2017 16.7236 19.665C16.2603 20.1283 16 20.7567 16 21.412V32.5296C16 33.1848 16.2603 33.8132 16.7236 34.2765C17.1869 34.7398 17.8153 35.0001 18.4706 35.0001H29.5882C30.2434 35.0001 30.8718 34.7398 31.3351 34.2765C31.7984 33.8132 32.0587 33.1848 32.0587 32.5296V31.2943"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M30.8267 16.4707L34.5326 20.1766M36.2434 18.4286C36.7299 17.9421 37.0033 17.2823 37.0033 16.5942C37.0033 15.9062 36.7299 15.2463 36.2434 14.7598C35.7569 14.2733 35.0971 14 34.409 14C33.721 14 33.0611 14.2733 32.5746 14.7598L22.1797 25.1177V28.8236H25.8855L36.2434 18.4286Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_9870_2523"
                x1="10.5"
                y1="8"
                x2="42.5"
                y2="54"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F96E3D" />
                <stop offset="1" stop-color="#A155F3" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className={""}>
          <FeaturesWithCard
            isFlip={true}
            min_height="240px"
            isCenter={false}
            grid={isMobile ? 1 :isTab?2: 3}
            data={featuresData2}
          />
        </div>
      </div>

      {/* <div data-aos="fade-left">
        <KeyDifferences data={keyDifferenceData} />
      </div>
      <div data-aos="fade-right">
        {" "}
        <AIFeatures
          isBot={false}
          data={{
            first_title: "Safety at Core",
            second_title: "",
            description:
              "Built-in Guardrails for Healthcare focused AGI like Intelligence",
            features: features2,
          }}
        />
      </div> */}

      <div
        data-aos="fade-left"
        style={{ gap: "2rem" }}
        className={`${styles.center} ${styles["cover-evolution"]} ${styles.cove} ${styles["flex-column"]}`}
      >
        <div className={`${styles["head-lg"]}`}>Creating Real Impact:</div>
        <div className={`${styles["head-md"]}`}>
          Why Hospitals Love HealthMates.AI
        </div>
        {/* <Spacer height={"2rem"} /> */}
        <div className={`${styles["head-sm"]} ${styles["font-w-4"]}`}>
          Hospitals that deploy HealthMates.AI see a return on <br /> investment
          within just 4-6 months!
        </div>

        <div className={""}>
          <FeaturesWithCard
            isFlip={true}
            min_height="231px"
            isCenter={false}
            grid={isMobile ? 1 : isTab?2:4}
            data={featuresData3}
          />
        </div>
      </div>
      <div data-aos="fade-right">
        <BeingRecorgnized />
      </div>
      <div data-aos="fade-left">
        <FlexContainer />
      </div>

      {/* <div data-aos="fade-left">
        <ProtectionSection />
      </div>
      <div data-aos="fade-right">
        {" "}
        <GoalSection />
      </div>
      <div data-aos="fade-left">
        <FeaturesSection />
      </div>
      <div data-aos="fade-right">
        <SecuritySection />
      </div>
      <div data-aos="fade-left">
        {" "}
        <Media />
      </div> */}
      <Spacer height={"2rem"} />
    </div>
  );
};
export default Home;
