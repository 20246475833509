import React, { useState } from "react";
import styles from "../../styles/main.module.css";
import Spacer from "../common/Spacer";
import bot from "../../assets/Home Page/chat-bot-outline.png";

import SmallFeatureCard from "./SmallFeatureCard";

import { useMediaQuery } from "react-responsive";
import { mobileDimension, tabletDimension } from "./CardSelector";

const AIFeatures = ({ data, isBot = true,isCenter=false,features=[]}) => {
  const isMobile=useMediaQuery(mobileDimension)
  const isDesktop=useMediaQuery(tabletDimension)
  console.log(data)
  

  return (
    <div
      style={{ gap: ".5rem" }}
      className={`${styles.center} ${styles["flex-column"]} ${styles["w-90"]} ${styles["m-auto"]}  ${styles.cover}`}
    >
      {/* {isBot ? (
        <div style={{ top: "35%", width: "60%" }} className={styles["pos-abs"]}>
          <img width="100%" src={dottedLine} alt="" />
        </div>
      ) : (
        <></>
      )} */}
      <div className={`${styles["head-md-lg"]}`}>
        {data?.first_title}
        <br />
        {data?.second_title}
      </div>

      <div className={`${styles[isMobile?"head-xsm":"head-sm"]}`}>{data?.description}</div>
      <Spacer height={"1rem"} />
      <div style={{ gap: "1rem" }} className={styles["feature-grid"]}>
        {isBot
          ? data?.features?.map((data, i) => (
              <>
                {i != 1 ?  <></> : isMobile?<></>:<Bot />}
                <Card data={data} />
              </>
            ))
          : data?.features?.map((data, i) => (
              <>
                <SmallFeatureCard
                  isKeyDifference={true}
                  title={data?.title}
                  subTitle={data?.subTitle}
                  img={data?.img}

                  isCenter={isCenter}
                />
              </>
            ))}
      </div>
    </div>
  );
};
export default AIFeatures;

export const Card = ({ data }) => {
  const isMobile=useMediaQuery(mobileDimension)

  return (
    <div
      style={{ lineHeight: 1, padding: "1.2rem 1.5rem" }}
      className={styles["feature-card"]}
    >
      <div 
      style={{ width: isMobile?"30%":"12%" }}
      >
        <img width="100%" src={data?.img} alt="" />
      </div>
      <div className={styles["head-md"]}>{data.title}</div>
      <div style={{ textAlign: "left" }} className={styles[isMobile?"head-xsm":"head-sm"]}>
        {data.description}
      </div>
    </div>
  );
};
const Bot = () => {
  return (
    <div className={`${styles['w-50']} ${styles['m-auto']}`}>
      <img width="100%" src={bot} alt="" />
    </div>
  );
};
