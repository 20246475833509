import React, { useState } from "react";
import styles from "../../styles/main.module.css";
import gridImage from "../../assets/Home Page/grid-image.png";
import heart from "../../assets/Home Page/icons/gradient-heart.png";
import money from "../../assets/Home Page/icons/gradiant-money.png";
import users from "../../assets/Home Page/icons/gradient-users.png";
import FeatureCard from "./FeatureCard";
import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
import { mobileDimension, tabletDimension } from "./CardSelector";
import { useNavigate } from "react-router-dom";

const FlexContainer = ({ isJoin = true, data }) => {
  const isMobile = useMediaQuery(mobileDimension);
  const isTablet = useMediaQuery(tabletDimension);
  const Navigate = useNavigate();
  return (
    <div
      style={{ borderRadius: "1rem", padding: isMobile ||isTablet?"1rem":"0rem 1rem" }}
      className={`${styles["flex-center"]} ${styles[isMobile ||isTablet ? "wrap" : ""]} ${
        styles['join-us']
      }  ${styles["m-auto"]} ${styles["w-80"]}`}
    >
      <div
        className={` ${styles["flex-column"]}`}
        style={{
          gap: isTablet ? "1rem" : "2rem",
          alignItems: "start",
          width: isMobile || isTablet ? "100%" : "55%",
          marginTop: isMobile ? "1rem" : "",
        }}
      >
        <div
          className={`${styles[isMobile ? "head-xlg" : isTablet?"head-md":"head-lg"]} ${
            styles["w-100"]
          } ${styles["left"]}`}
        >
          Inviting you to be a part {!isMobile?<br />:<></>} of this Epic Journey
        </div>
        <div className={`${styles["head-sm"]} ${styles["font-w-4"]}`}>
          {isJoin ? (
            <>
              <b>For Hospitals & Clinics:</b>
              <br />
              Schedule a free assessment to understand how HealthMates can help
              you
              <br />
              <Spacer height={"1rem"} />
              <b>For Partners & Investors:</b>
              <br />
              Explore opportunities to collaborate and innovate with us
              <Spacer height={"2rem"} />
              <div className={`${styles["flex-between"]} ${styles["wrap"]} `}>
                <div className={`${styles["flex-gap"]} ${styles["wrap"]} `}><b>Email:</b>
                <a style={{ color:"white" }} href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</a>
                </div>
              <Spacer height={"3rem"} />
                
                <div className={`${styles["flex-gap"]} ${styles["wrap"]} `}>
                  <b>Location:</b>New Delhi,India
                </div>
              </div>
            </>
          ) : (
            <>
              Empower your team: Proactively executing tasks and collaborating
              seamlessly with AI and human counterparts. Delight your patient:
              <Spacer height={"1rem"} />
              Proactively executing tasks and collaborating seamlessly with AI
              and human counterparts.
            </>
          )}
        </div>
        <div className={`${styles['flex-gap']} ${styles['wrap']} ${styles['gap-1']}`}>  <button
          onClick={() => Navigate("/contactus")}
          style={{
            borderRadius: "2rem",
            // padding:"1.5rem 3rem"
          }}
          className={`${styles["head-sm"]}  ${styles["white-button-contact"]}`}
        >
          {isJoin ? "Say Hi!" : "Get Started Now"}
        </button>  <button
          onClick={() => Navigate("/partners")}
          style={{
            borderRadius: "2rem",
            // padding:"1.5rem 3rem"
          }}
          className={`${styles["head-sm"]}  ${styles["white-button-contact"]}`}
        >
          {isJoin ? "Why Partner?" : "Get Started Now"}
        </button></div>
      
      </div>
      {isMobile ||isTablet ? (
        <></>
      ) : (
        <div className={styles[isTablet ? "w-40" : isMobile ? "w-80" : "w-35"]}>
          <img width="100%" src={gridImage} alt="" />
        </div>
      )}
    </div>
  );
};
export default FlexContainer;
