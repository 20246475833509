export const add_contact_us= async (data) => {
  const headers={
    // Accept: "application/json",
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }

    const response = await fetch(process.env.REACT_APP_API + "/contact/add_contact_us/", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
    });
    if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return data;
      } else if (response.status === 405 || response.status === 403) {
        // settextData(response.status);
        // setIsModal(true);
        localStorage.clear();
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        return [];
      } else if (response.status !== 200 || response.status !== 201) {
        return [];
      }

}
export const add_join_team= async (data) => {
    const headers = {
      // "Content-type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
        // sessionid: JSON.parse(localStorage.getItem("sessionId")),
    }
  console.log()
    const response = await fetch(process.env.REACT_APP_API + "/join_team/add_join_team/", {
        method: "POST",
        headers: headers,
        body: data
    });
    if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return data;
      } else if (response.status === 405 || response.status === 403) {
        // settextData(response.status);
        // setIsModal(true);
        localStorage.clear();
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        return [];
      } else if (response.status !== 200 || response.status !== 201) {
        return [];
      }

}
export const add_subscription= async (data) => {
  const headers={
    // Accept: "application/json",
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }
console.log()
  const response = await fetch(process.env.REACT_APP_API + "/contact/add_subscription/", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data)
  });
  if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 405 || response.status === 403) {
      // settextData(response.status);
      // setIsModal(true);
      localStorage.clear();
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      return [];
    } else if (response.status !== 200 || response.status !== 201) {
      return [];
    }

}


export const delete_subscription= async (data) => {
  const headers={
    // Accept: "application/json",
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }
console.log(data)
  const response = await fetch(process.env.REACT_APP_API + "/contact/add_subscription/", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data)
  });
  if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return data;
    } else if (response.status === 405 || response.status === 403) {
      // settextData(response.status);
      // setIsModal(true);
      localStorage.clear();
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      return false;
    } else if (response.status !== 200 || response.status !== 201) {
      return false;
    }

}