import React from "react";
import styles from "../../styles/main.module.css";
import nasscom from "../../assets/Home Page/nasscom.png";
import business from "../../assets/Home Page/bussness.png";
import { useMediaQuery } from "react-responsive";
import { mobileDimension } from "./CardSelector";
import Spacer from "../common/Spacer";

const BeingRecorgnized = () => {
  const isMobile = useMediaQuery(mobileDimension);
  return (
    <div
      style={{ gap: "3rem" }}
      className={`${styles["cover-evolution"]}  ${styles["center"]} ${styles["flex-column"]}`}
    >
      <div className={`${styles["head-lg"]}`}>And being recognized for it</div>
      <div className={`${styles["flex-center"]} ${styles["wrap"]} `}>
        <div
          style={{ gap: "1rem" }}
          className={`${styles[isMobile ? "w-100" : "w-50"]} ${
            styles["flex-column"]
          }  `}
        >
          <div
            className={`${styles["nasscom-img"]} ${
              styles[isMobile ? "w-100" : "w-50"]
            }`}
          >
            <img width="100%" src={nasscom} alt="" />
          </div>
          <div
            className={` ${styles[isMobile ? "w-100" : "w-50"]}${
              styles["head-sm"]
            }`}
          >
            Nasscom DeepTech Club Memebr
          </div>
        </div>
        {isMobile?<><Spacer height="1rem"/></>:<></>}
        <div
          style={{ gap: "1rem" }}
          className={` ${styles["flex-column"]}   ${
            styles[isMobile ? "w-100" : "w-50"]
          }`}
        >
          <div className={styles["business-img"]}>
            <img width="100%" src={business} alt="" />
          </div>
          <div
            className={` ${styles[isMobile ? "w-100" : "w-50"]} ${
              styles["head-sm"]
            }`}
          >
            Economic Times Now Emerging AI Startup of the Year 2025
          </div>
        </div>
      </div>
    </div>
  );
};
export default BeingRecorgnized;
