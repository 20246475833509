import React from "react";
import styles from "../../styles/main.module.css";
import background from "../../assets/Home Page/cover.png";
import { useMediaQuery } from "react-responsive";
import Spacer from "../common/Spacer";

const MainCover = ({ title = "", subTitle1 = "",subTitle2 = "" }) => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  const isDesktop = useMediaQuery({ minWidth: "501px", maxWidth: "900px" });
  return (
    <>
      <div className={`${styles?.["w-85"]} ${styles?.["m-auto"]} `}>
        <div
          style={{ 
            alignItems: isMobile || isDesktop ? "center" : "start",
          }}
          className={`${styles?.["about-cover"]}  ${styles?.["flex-column-end"]}  `}
        >
          <Spacer height={"1rem"}/>
          <h1
            className={`${isMobile ? styles?.["w-100"] : styles?.["w-100"]} ${
              isMobile ? styles?.["center"] : ""
            } ${styles['head-lg']} `}
          >
            {title}
          </h1>
          <Spacer height={"1rem"}/>

        </div>
        <div className={styles['cover-image']}>
          <img className={styles['cover-image']} width="100%" src={background} alt="" />
        </div>
        <div


          className={` ${isMobile ? styles?.["p-t-1"] : styles?.["p-t-3"]} ${
            isMobile ? styles?.["p-b-1"] : styles?.["p-b-3"]
          } 
        
          
           ${styles['fade-content']}
          
          `}
        >
          
            {subTitle1}
            <br />
            <Spacer height={"2rem"}/>
            {subTitle2}
       
        </div>
      </div>
    </>
  );
};
export default MainCover;
