import React from "react";
import styles from "../../styles/main.module.css";
import { useNavigate } from "react-router-dom";
import { BsArrowRightCircleFill } from "react-icons/bs";
import Spacer from "../common/Spacer";

        
const RequestDemo = () => {
    const navigate=useNavigate()
    return (
        <div className={`${styles['flex-column']} ${styles['cover-req-demo']}`}>
                 <div className={` ${styles?.["head-lg"]} ${styles?.["center"]} `}>
                 A Revolution called HealthMates<br />
        {/* A revolution called HealthMates  */}
        </div>

        <div className={` ${styles?.["head-sm"]} ${styles?.["font-w-4"]} ${styles?.["center"]} `}>
        A human-capable, safety-focused, non-diagnostic,
          <br />  healthcare tuned Artificial General Intelligence like system
        </div>
       <div>
        <Spacer height={"2rem"}/>
        <button
          onClick={() => navigate("/reqDemo")}
          className={`${styles['req-button']} ${styles?.["head-sm"]} ${styles?.["flex-gap"]}  ${styles['primary-color']}  ${styles['gradiant-button']}`}
        >
        Request Demo <BsArrowRightCircleFill size={28} />
        </button></div>
        </div>
    );
};
export default RequestDemo;