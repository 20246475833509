import React, { useState } from "react";
import styles from "../../styles/main.module.css";

import ritik from "../../assets/Home Page/ritik.png"
import star from "../../assets/Home Page/icons/star.png"

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
const BotCard = ({
  data,isOpen,setIsOpen,setModalData
}) => {
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const isMobile=useMediaQuery({maxWidth:"500px"})

  const handleCardSelect=(data)=>{
    console.log(data,"hello")
    localStorage.setItem("botDetails",JSON.stringify(data))
    navigate("/profile")
  }
  return (
    <>
 
    
    <div onClick={()=>{setIsOpen(true);setModalData(data)}} style={isMobile?{ width:"50%" }:{}} className={`${styles?.["bot-card"]} ${styles?.["pointer"]}   ${styles?.["m-auto"]} `}>
      <div className={styles['star-image']} style={{ width:"10%" }}>
        <img width="100%" src={star} alt="" />
      </div>
      <div  style={{ position: "relative",width:"70%" }}>
        <img width="100%" style={{ borderRadius:"50%" }} src={data?.image} alt="" />
      </div>

      <div className={`${styles?.["m-l-3"]} ${styles?.["language-content"]}`}>
        <div style={{ fontSize:"1.6rem" }} className={`${styles?.["head-lg"]}`}>{data?.name}</div>

     </div>
     <button style={{ borderRadius:"2rem",fontSize:".9rem" }} className={styles['white-button']}>{data?.role}</button>
    </div>
    </>
  );
};
export default BotCard;
