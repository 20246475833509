import React, { useState } from "react";
import styles from "../styles/main.module.css";
import bitcoin from "../assets/Home Page/icons/bitcoin-icons_verify-filled.png";
import brush from "../assets/Home Page/icons/brush.png";
import graduation from "../assets/Home Page/icons/graduation.png";
import cloud from "../assets/Home Page/icons/material-symbols_cloud.png";
import notes from "../assets/Home Page/icons/notes.png";
import doctor from "../assets/Home Page/icons/mdi_doctor.png";
import speak from "../assets/Home Page/icons/speak.png";
import image from "../assets/Home Page/icons/ri_image-ai-fill.png";
import tick from "../assets/Home Page/icons/tick-circle.png";
import live from "../assets/Home Page/icons/live-chat 1.png";
import smile from "../assets/Home Page/icons/smile.png";
import sharing from "../assets/Home Page/icons/sharing.png";
import star from "../assets/Home Page/icons/star.png";
import patient from "../assets/Home Page/icons/mdi_patient.png"
import usi_grids from "../assets/Home Page/icons/uis_grids.png";
import speech from "../assets/Home Page/icons/speech-synthesis (1) 1.png";
import KeyDifferences from "../components/home/KeyDifferences";
import FeaturesWithCard from "../components/common/FeaturesWithCard";
import Spacer from "../components/common/Spacer";
import FlexContainer from "../components/home/FlexContainer";
import Header from "../components/Founder/Header";
import { useMediaQuery } from "react-responsive";
import { mobileDimension } from "../components/home/CardSelector";

const AiModel = () => {
  const isMobile = useMediaQuery(mobileDimension);

  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Vison", "Speech"];
  const keyDifferenceData = {
    title:
      "Andromeda Architecture: Multi-Agent AI for Healthcare A Multi-Agent AI Model for Healthcare Execution",
    desc: "A Multi-Agent AI Model for Healthcare Execution. The Andromeda Architecture is a modular, agentic AI ecosystem where multiple fine-tuned micro AI agents operate collaboratively to form a single, outcome-driven HealthMate. This structure eliminates the limitations of standalone AI models by creating an orchestrated system of task-specific intelligence that enhances efficiency, governance, and reliability.",
    features: [
      {
        title: "Dynamic Execution",
        img: usi_grids,
        subTitle:
          "AI teammates infer, analyze, and act in real-time based on structured clinical and operational workflows.",
      },
      {
        title: "Governance & Compliance",
        img: tick,
        subTitle:
          "AI actions are validated through regulatory-compliant guardrails, ensuring adherence to HIPAA, GDPR, PDP, and NABH.",
      },
      {
        title: "Edge & Cloud Optimization",
        img: cloud,
        subTitle:
          "AI models are deployed efficiently across cloud, on-premise, and edge infrastructure, ensuring low-latency execution.",
      },
      {
        title: "AI Agent Collaboration",
        img: sharing,
        subTitle:
          " Task-specific AI agents work in parallel to ensure accuracy in medical coding, diagnostics, triage, and operational management.",
      },
    ],
  };
  const featuresData = {
    title: " Large Language Models (LLM): Healthcare-Tuned AI Cognitione",
    desc: "",
    features: [
      {
        title: "Domain-specific knowledge retrieval",
        img: graduation,
        subTitle: "",
      },
      {
        title: "Real-time clinical documentation ",
        img: notes,
        subTitle: "",
      },
      {
        title: "Adaptive conversational AI",
        img: star,
        subTitle: "",
      },
      {
        title: "Prevention of AI hallucinations",
        img: brush,
        subTitle: "",
      },
    ],
  };

  const tabsData = [
    {
      name: "Vision",
      title: "Computer Vision Technology (CVT): AI That Sees & Interprets",
      desc: "",
      features: [
        {
          title: "Automated medical imaging analysis",
          img: image,
          subTitle: "",
        },
        {
          title: "AI-powered rehabilitation tracking ",
          img: patient,
          subTitle: "",
        },
        {
          title: "Real-time surgical AI assistance,",
          img: doctor,
          subTitle: "",
        },
        {
          title: "Automated compliance verification",
          img: bitcoin,
          subTitle: "",
        },
      ],
    },
    {
      name: "Speech",
      title: " Large Language Models (LLM): Healthcare-Tuned AI Cognitione",
      desc: "",
      features: [
        {
          title: "Domain-specific knowledge retrieval",
          img: speech,
          subTitle: "",
        },
        {
          title: "Real-time clinical documentation ",
          img: smile,
          subTitle: "",
        },
        {
          title: "Adaptive conversational AI",
          img: live,
          subTitle: "",
        },
        {
          title: "Prevention of AI hallucinations",
          img: speak,
          subTitle: "",
        },
      ],
    },
  ];

  const keyData = {
    title: "DAG & GraphQL: AI Execution & Orchestration",
    desc: "HealthMates leverages Directed Acyclic Graph (DAG) reasoning and GraphQL APIs to ensure low-latency, structured execution of AI-driven tasks",
    features: [
      {
        title: "Task prioritization & execution sequencing",
        img: "",
        subTitle: "allowing AI to process complex workflows efficiently.",
      },
      {
        title: "Real-time data exchange between AI agents,",
        img: "",
        subTitle:
          "ensuring coordinated responses and seamless decision-making.",
      },
      {
        title: "Event-driven AI automation",
        img: "",
        subTitle:
          "allowing AI to adapt to evolving healthcare scenarios in real-time.",
      },
      {
        title: "Scalability for large-scale healthcare environments",
        img: "",
        subTitle:
          "ensuring AI can be customized for hospital-specific workflows.",
      },
    ],
  };
  return (
    <div
      style={{ gap: isMobile?"2rem":"5rem" }}
      className={`${styles["flex-column-str"]} ${styles[""]}`}
    >
      <Spacer height={".1rem"}/>
      <div data-aos="fade-down">
      <Header 
      header={"Revolutionizing Healthcare with AI-Driven Teammates"}
      bigMessage={"At HealthMates.AI, we’re transforming how AI integrates into healthcare. Our multi-agent AI framework, built on the Andromeda Architecture, goes beyond simple automation—acting as intelligent, compliant, and real-time AI teammates that enhance decision-making across clinical and operational workflows."}
      />
      </div>
      <div data-aos="fade-left">
        <KeyDifferences isGradiant={true} data={keyDifferenceData} />
      </div>
      <div data-aos="fade-right">
        <FeaturesWithCard grid={isMobile?2:4} isCenter={true} data={featuresData} />
      </div>
      <div
      data-aos="fade-left"
        className={`${styles["flex-column"]} ${styles["w-90"]} ${styles["m-auto"]}  ${styles["tab-comp"]}`}
      >
        <div className={styles["outline-tab-container"]}>
          {tabsData?.map((tab, i) => (
            <div
              className={`${
                styles[activeTab == i ? "outline-active-tab" : "outline-tab"]
              }`}
              onClick={() => setActiveTab(i)}
            >
              {tab?.name}
            </div>
          ))}
        </div>
        <Spacer height={"2rem"} />
        <FeaturesWithCard
          border={true}
          grid={isMobile?2:4}
          isCenter={true}
          data={tabsData?.[activeTab]}
        />
      </div>
      <div data-aos="fade-right">
        <KeyDifferences isGradiant={false} data={keyData} />
      </div>
      <div data-aos="fade-left">
        <FlexContainer isJoin={true} />
      </div>

      <Spacer height={"1rem"} />
    </div>
  );
};
export default AiModel;
